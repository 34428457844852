// This file defines hooks that can be used throughout the app.
// Their types are derived automatically from the store in store.ts.
// These hooks should used instead of plain `useDispatch` and `useSelector`.

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";

type DispatchFunc = () => AppDispatch;

/** Dispatch an action to the Redux store. */
export const useAppDispatch: DispatchFunc = useDispatch;

/** Select a value from the Redux store and subscribe to updates. */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
