import React from "react";
import { GetPostsOptions, WordPressPost } from "./GetPosts";

interface GetPostParams {
  keyword?: string;
  categoryIds?: string;
  tagIds?: string;
  pageSize: string;
  page: string;
  wordpressDomain: string;
}

/**
 * A hook wrapper for the GetPosts function, for ease of use in React
 * @param options - See GetPostOptions in GetPosts.ts
 * @returns An array of WordPress blog posts
 */
export function useGetPosts(options: GetPostsOptions) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<WordPressPost[] | null>(null);
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    setLoading(true);

    async function getPosts(options: GetPostsOptions) {
      const params: GetPostParams = {
        page: options.page.toString(),
        pageSize: options.pageSize.toString(),
        wordpressDomain: options.wordpressDomain,
      };

      if (options.keyword) params.keyword = options.keyword;
      if (options.categoryIds && options.categoryIds.length !== 0) {
        params.categoryIds = options.categoryIds?.join(",");
      }
      if (options.tagIds && options.tagIds.length !== 0)
        params.tagIds = options.tagIds?.join(",");

      const searchParams = new URLSearchParams({ ...params });

      const response = await fetch(
        `/api/wordpress-posts?${searchParams.toString()}`
      );
      if (response.ok) {
        const json = await response.json();
        setData(Object.keys(json).map((key) => json[key]));
        setError(null);
      } else {
        setData(null);
        setError(
          new Error("There was an error retrieving WordPress blog posts")
        );
      }

      setLoading(false);
    }

    if (options.ready) {
      getPosts(options);
    }
  }, [options.ready, options.page]);

  return {
    loading: loading,
    data: data,
    error: error,
  };
}
