import { Box, Collapse, Grid } from "@mui/material";
import { AccordionProps } from "./FAQAccordion";
import React from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

/**
 * Represents an accordion where each item is outlined
 * Examples of placements of this component can be seen on:
 *   The www homepage - Pivot Health Mission section
 * @param props - See AccordionProps in ./FAQAccordion
 * @returns An accordion-style widget where each item is outlined separately
 */
export default function OutlinedAccordion(props: AccordionProps) {
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);

  return (
    <Box>
      {props.accordionItems.map((accordionItem, index) => {
        return (
          <Box
            key={index}
            sx={{
              border: "1px solid #2c3a524a",
              borderRadius: "20px",
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            <Box
              onClick={() => {
                if (openIndex === index) setOpenIndex(null);
                else setOpenIndex(index);
              }}
              sx={{
                display: "flex",
                flex: "space-between",
                fontSize: props.titleFontSize,
                fontWeight: props.titleFontWeight,
                width: "100%",
              }}
            >
              <Grid container>
                <Grid item xs={11} sx={{ paddingTop: "8px" }}>
                  {accordionItem.title}
                </Grid>
                <Grid item xs={1}>
                  {openIndex === index ? (
                    <KeyboardArrowUp sx={{ fontSize: "40px" }} />
                  ) : (
                    <KeyboardArrowDown sx={{ fontSize: "40px" }} />
                  )}
                </Grid>
              </Grid>
            </Box>
            <Collapse
              data-testid={
                openIndex === index
                  ? `collapse-${index}-open`
                  : `collapse-${index}-closed`
              }
              in={openIndex === index}
            >
              <Box
                component="div"
                sx={{ fontSize: props.contentFontSize }}
                dangerouslySetInnerHTML={{ __html: accordionItem.content }}
              />
            </Collapse>
          </Box>
        );
      })}
    </Box>
  );
}
