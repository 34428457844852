import { useMutation } from "@apollo/client";
import { documents } from "dtc-graphql-schema";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { useEffect } from "react";
import type { SessionContext } from "services/tron-session-manager";
import { useAppDispatch } from "../../hooks";
import type { HcState } from "./HcState";
import { DEFAULT_AGENT_ID } from "./constants";
import { update } from "./hcSlice";
import {
  HC_SESSION_COOKIE,
  HealthcareCookies,
  useHcCookies,
} from "./useHcCookies";
import useHcStorage from "./useHcStorage";

// The bulk of the logic in this file is based on @hc-frontend/shared-assets/src/js/sem/session-manager/getSession.js
// Hopefully, this code is simplified and stripped down, following conventions that are appropriate for React hooks.

/**
 * Hook to perform initialization logic, mostly related to managing the `hc` object in `localStorage`
 * and session management through Healthcare.com's APIs.
 *
 * The logic in this hook is tightly coupled to the `hc` object and the quirks of Healthcare's session APIs.
 * Over time, as we migrate away from the old DTC platform and introduce a Pivot Health session API, we
 * should decompose this into more generic, loosely coupled hooks and eventually deprecate this hook
 * altogether.
 *
 * Required contexts:
 * - Redux
 * - Apollo
 * - Next.js Router (in tests, mock the `useRouter` hook)
 */
export function useHc() {
  const hc = useHcStorage();
  const dispatch = useAppDispatch();
  const { query, isReady } = useRouter();
  const [cookies, setCookies] = useHcCookies();
  const [createSession, { called, data, loading, error }] = useMutation(
    documents.CREATE_SESSION
  );

  // Trigger our mutation to initialize a session
  useEffect(() => {
    const runEffect = async () => {
      // If the router isn't ready yet, return and wait until it is ready.
      // We need the query string values from the router to determine if we need a new session.
      if (!isReady) {
        console.log("Router not ready");
        return;
      }

      // If the conditions are appropriate for us to create a new session,
      // then trigger the mutation to start the session.
      const createNewSession = shouldCreateSession(hc, query, cookies);
      if (!loading && !called && createNewSession) {
        console.log("Creating new session...");
        try {
          const context = getPayload(hc, query, cookies);
          const result = await createSession({
            variables: { payload: { context: context } },
          });

          // If we have a user ID and a session ID, then update the HC storage object,
          // cookies, and hc-session localStorage object to reflect the new values.
          const userId = result.data?.createSession?.UserId;
          const sessionId = result.data?.createSession?.sessionId;
          if (userId && sessionId) {
            console.log(
              `Updating state with user ID ${userId} and session ID ${sessionId}`
            );
            dispatch(
              update({
                ...hc,
                healthcareSession: sessionId,
                healthcareUserId: userId,
                utm: {
                  ...hc.utm,
                  utm_source: context.Label?.Src || hc.utm.utm_source,
                  utm_medium: context.Label?.Medium || hc.utm.utm_medium,
                  utm_campaign: context.Label?.Campaign || hc.utm.utm_campaign,
                  utm_term: context.Label?.Keyword || hc.utm.utm_term,
                  utm_content:
                    getQueryString(query, "utm_content") || hc.utm.utm_content,
                },
                ircid: context.Label?.Ircid || hc.ircid,
                gclid: context.Label?.Gclid || hc.gclid,
                /* c8 ignore next 2 */
                landing_url: context.Label?.LandingPage || "",
                agent_id: context.Label?.agent_id || hc.agent_id,
                contact: {
                  ...hc.contact,
                  email_address: context.Email || hc.contact.email_address,
                },
                // These parameters are theoretically tracked in storage,
                // though they aren't used in the session management API.
                // We'll capture them here just in case.
                aid: getQueryString(query, "aid") || hc.aid,
                pubid:
                  parseInt(getQueryString(query, "pubid") || "") || hc.pubid,
                subid:
                  parseInt(getQueryString(query, "subid") || "") || hc.subid,
                irpid: getQueryString(query, "irpid") || hc.irpid,
                version: getQueryString(query, "version") || hc.version,
                source: context.Label?.Src || hc.utm.utm_source,
                msclkid: getQueryString(query, "msclkid") || hc.msclkid,
                agency_id: getQueryString(query, "agency_id") || hc.agency_id,
              })
            );

            setCookies({
              user: userId,
              session: sessionId,
            });

            setHcSessionLocalStorage(sessionId, hc.contact.email_address);
          }
        } catch (error) {
          console.error("Error during session initialization:", error);
        }
      } else if (!createNewSession) {
        console.log("Criteria not met to create a new session");
      }
    };
    runEffect();
  }, [
    data,
    error,
    loading,
    createSession,
    dispatch,
    hc,
    query,
    cookies,
    setCookies,
    isReady,
    called,
  ]);

  return { error: error };
}

/**
 * Generate the SessionContext object sent to the session manager API.
 *
 * @see `@hc-frontend/shared-assets` for the original logic this is based on.
 */
function getPayload(
  state: HcState,
  query: ParsedUrlQuery,
  cookies: HealthcareCookies
): SessionContext {
  const userId = getQueryString(query, "uid") || Cookies.get("healthcareUser");
  const useExistingUserId =
    userId && userIdIsValid(userId) && !userIdMismatch(query, cookies);

  return {
    Email: state.contact.email_address,
    TrafficSource: getQueryString(query, "utm_source") || state.utm.utm_source,
    BiddingToolId: state.bt_sid,
    Label: {
      Channel: "SEM",
      Domain: location.hostname,
      Product: "HEALTH",
      LandingPage: location.href,
      agent_id:
        getQueryString(query, "agent_id") || state.agent_id || DEFAULT_AGENT_ID,
      Src:
        getQueryString(query, "utm_source") ||
        state.source ||
        state.utm.utm_source,
      PublisherCampaignId:
        getQueryString(query, "pubid") ||
        (state.pubid !== 0 ? state.pubid.toString() : undefined),
      SubId:
        getQueryString(query, "subid") ||
        (state.subid !== 0 ? state.subid.toString() : undefined),
      Medium: getQueryString(query, "utm_medium") || state.utm.utm_medium,
      Keyword: getQueryString(query, "utm_term") || state.utm.utm_term,
      Campaign: getQueryString(query, "utm_campaign") || state.utm.utm_campaign,
      Ircid: getQueryString(query, "ircid") || state.ircid,
      Gclid: getQueryString(query, "gclid") || state.gclid,
    },
    UserContext: {
      /* c8 ignore next 7 */
      UserId: useExistingUserId ? userId : undefined,
      UserAgent: navigator.userAgent,
      SessionId: useExistingUserId
        ? getQueryString(query, "sid") ||
          getQueryString(query, "healthcareSession") ||
          cookies.session
        : undefined,
    },
  };
}

/** Get a query string value from the URL. Not case sensitive. Ignores repeated parameters (i.e. arrays). */
function getQueryString(
  query: ParsedUrlQuery,
  key: string
): string | undefined {
  const value: string | string[] | undefined =
    query[key.toLowerCase()] || query[key.toUpperCase()];
  if (Array.isArray(value)) {
    return value[0];
  } else {
    return value;
  }
}

/**
 * Determine whether or not to create a new session.
 *
 * We should create a new session if ANY of the following is true:
 * - HC storage object and URL params do not match
 * - Healthcare user ID from the URL is valid but does not match the ID in the Healthcare cookie
 * - There is a session ID in the URL (not clear why this matters, but apparently it does)
 * - We don't have a Healthcare session ID in the HC session cookie
 * - The email address in HC storage does not match the one in the session cookie
 *
 * @param hc - Current Healthcare session state from localStorage
 * @param query - URL query string params
 * @returns True if we should create a new session, else false.
 */
function shouldCreateSession(
  hc: HcState,
  query: ParsedUrlQuery,
  cookies: HealthcareCookies
): boolean {
  return (
    urlAndStateMismatch(hc, query) ||
    userIdMismatch(query, cookies) ||
    sessionIdInUrl(query) ||
    !cookies.session ||
    emailSessionMismatch(hc, cookies)
  );
}

/**
 * Determine whether the HC storage object matches the URL parameters. If they do not match, we should
 * create a new session.
 *
 * It is only considered a mismatch if a value is present in the query string AND does not match the
 * value in storage. If there is no value in the query string, then it does not matter what is found
 * in storage, as it is not considered a mismatch.
 *
 * @param hc - Healthcare storage object
 * @param query - URL query string params
 * @returns True if the URL and HC storage do not match, indicating that we should create a new session.
 */
function urlAndStateMismatch(hc: HcState, query: ParsedUrlQuery): boolean {
  // Original list from @hc-frontend/shared-assets/src/js/sem/session-manager/_checkChangesInUrl.js
  const PAIRS: [string, string][] = [
    ["aid", hc.aid],
    ["pubid", hc.pubid !== 0 ? hc.pubid.toString() : ""],
    ["irpid", hc.irpid],
    ["ircid", hc.ircid],
    ["src", hc.source],
    ["subid", hc.subid !== 0 ? hc.subid.toString() : ""],
    ["utm_source", hc.utm.utm_source],
    ["utm_campaign", hc.utm.utm_campaign],
    ["utm_medium", hc.utm.utm_medium],
    ["utm_content", hc.utm.utm_content],
    ["utm_term", hc.utm.utm_term],
    ["msclkid", hc.msclkid],
    ["gclid", hc.gclid],
    ["agent_id", hc.agent_id],
  ];

  const mismatch = PAIRS.some((pair) => {
    const [key, hcVal] = pair;
    const queryVal = getQueryString(query, key) || "";
    // Only count it as a mismatch if there was actually a query string value.
    // Most of the time, there will not be one, even if we have a corresponding value in storage.
    return queryVal && queryVal !== hcVal;
  });

  return mismatch;
}

/**
 * Determine whether there is a mismatch between the user ID in the URL and
 * the one in the "healthcareUser" cookie.
 *
 * For this to return true, all of the following must be true:
 * - There must be a user ID in URL as the "uid" param
 * - The user ID must be valid but different from the one in the cookie OR the user ID is invalid
 *
 * @param query - URL query string parameters
 * @param cookies - Standard Healthcare cookies
 * @returns True if the user ID in the URL does not match the one in the cookie
 */
function userIdMismatch(
  query: ParsedUrlQuery,
  cookies: HealthcareCookies
): boolean {
  const uidFromQuery = getQueryString(query, "uid");
  const uidFromCookie = cookies.user;

  // No ID in URL => no mismatch
  if (!uidFromQuery) return false;

  // Invalid ID in URL => mismatch
  if (!userIdIsValid(uidFromQuery)) return true;

  // URL and cookie do not match => mismatch
  return uidFromQuery !== uidFromCookie;
}

/** Check if a user ID has a valid structure */
function userIdIsValid(userId: string): boolean {
  // UID regex from @hc-frontend/shared-assets/src/js/sem/session-manager/_healthcareUser.js
  const UID_FORMAT = /^[A-Z0-9]{28,36}$/;
  return UID_FORMAT.test(userId);
}

/** Determine if there's a session ID in the URL, which would require us to create a new session (for some reason). */
function sessionIdInUrl(query: ParsedUrlQuery): boolean {
  // !!x is true if x is truthy and false if x is falsy, which is all we care about here.
  return (
    !!getQueryString(query, "sid") || !!getQueryString(query, HC_SESSION_COOKIE)
  );
}

/**
 * Determine whether the value stored in `localStorage.getItem("hc-session")` matches the
 * session ID and email address in `hc`.
 * @param hc - HC storage object
 * @param cookies - Healthcare cookies
 * @returns True if the email and session ID stored in local storage do not match the values in `hc` and `sessionId`
 */
function emailSessionMismatch(
  hc: HcState,
  cookies: HealthcareCookies
): boolean {
  const hcSession = getHcSessionLocalStorage();
  const email = hc.contact.email_address;
  const sessionId = hc.healthcareSession || cookies.session;
  return email !== "" && `${sessionId}-${email}` !== hcSession;
}

/**
 * Get the value of the `hc-session` string from `localStorage`.
 *
 * Note that this is different from the `hc` storage object. This one appears to contain the session ID and email address.
 * It is not obvious what utility this has other than backwards compatibility.
 */
function getHcSessionLocalStorage(): string | null {
  return localStorage.getItem("hc-session");
}

/**
 * Set the value of the `hc-session` string in `localStorage`.
 *
 * Note that this is different from the `hc` storage object. This one appears to contain the session ID and email address.
 * It is not obvious what utility this has other than backwards compatibility.
 */
function setHcSessionLocalStorage(sessionId: string, email?: string): void {
  // Yes, this really does save "abc.xyz-undefined" if "email" is "undefined."
  // No, I do not know why that is the desired behavior, but it is preserved for backwards compatibility.
  return localStorage.setItem("hc-session", `${sessionId}-${email}`);
}

export default useHc;
