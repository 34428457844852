import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Link as MUILink } from "@mui/material";
import Link from "next/link";

interface HeaderDropdownOption {
  text: string;
  url: string;
}

export interface HeaderDropdownProps {
  title: string;
  dropdownOptions: HeaderDropdownOption[];
}

export default function HeaderDropdown(props: HeaderDropdownProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        disableRipple
        id="menu-dropdown-btn"
        aria-controls={open ? "menu-dropdown-btn" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          backgroundColor: "#fff",
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: "14px",
          transition: "color .300s ease-in-out",
          color: "#323950",
          paddingLeft: "0px",
          paddingRight: "0px",
          "&:hover": {
            backgroundColor: "#fff",
            color: "#FFC057",
          },
        }}
      >
        {props.title} {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Button>
      <Menu
        id="menu-dropdown-content"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-dropdown-btn",
        }}
      >
        {props.dropdownOptions.map((dropdownOption, index) => {
          return (
            <MenuItem key={index}>
              <MUILink
                href={dropdownOption.url}
                target="_blank"
                component={Link}
                underline="none"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "#323950",
                }}
              >
                {dropdownOption.text}
              </MUILink>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
