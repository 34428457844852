import React from "react";
import { WordPressCategory, GetCategories } from "./GetCategories";

/**
 * A hook wrapper for the GetCategories function, for ease of use in React
 * @returns A [data, error, loading] object with the data field being an array of all categories
 */
export function useGetCategories(wordpressDomain: string) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<WordPressCategory[] | null>(null);
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    // Set loading flag to true
    setLoading(true);

    // Get the categories
    GetCategories(100, wordpressDomain)
      .then((categories) => {
        setData(categories);
        setError(null);
      })
      .catch((error) => {
        setData(null);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    loading: loading,
    data: data,
    error: error,
  };
}
