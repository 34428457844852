import Cookies from "js-cookie";
import { useState } from "react";

/** Standard Healthcare.com cookie values. */
export interface HealthcareCookies {
  /** User ID */
  user?: string;
  /** Session ID */
  session?: string;
}

/** HC user ID cookie name */
export const HC_USER_COOKIE = "healthcareUser";
/** HC session ID cookie name */
export const HC_SESSION_COOKIE = "healthcareSession";

export type CookieSetter = (cookies: HealthcareCookies) => void;

/**
 * A hook, similar to React's `useState`, that can be used to get and set the value
 * of Healthcare.com cookies.
 *
 * WARNING: This hook only maintains the state in a single component. If you call
 * this hook from multiple components, the components' view of the cookies will
 * diverge if the cookies are updated, as it does not watch for changes to the cookie
 * values after they are first retrieved. If you need to use this hook in multiple
 * locations, consider updating it to manage cookie state using Redux. Ideally,
 * however, you should avoid sharing cookies (or any state) between components.
 *
 * @returns A tuple containing:
 * 1. The current cookie values
 * 2. A function that can be used to save the values as cookies and update React state
 */
export function useHcCookies(): [HealthcareCookies, CookieSetter] {
  const [cookies, setCookies] = useState<HealthcareCookies>({
    user: Cookies.get(HC_USER_COOKIE),
    session: Cookies.get(HC_SESSION_COOKIE),
  });

  const saveCookies = (cookies: HealthcareCookies) => {
    const now = new Date();
    if (cookies.user) {
      Cookies.set(HC_USER_COOKIE, cookies.user, {
        expires: 25 * 365, // User cookie expires after 25 years
      });
    }

    if (cookies.session) {
      Cookies.set(HC_SESSION_COOKIE, cookies.session, {
        expires: now.setHours(now.getHours() + 4), // Session cookie expires after 4 hours
      });
    }

    setCookies(cookies);
  };

  return [cookies, saveCookies];
}
