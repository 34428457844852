import {
  PreloadedState,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import hcReducer from "./features/hc/hcSlice";
import userReducer from "./features/user/userSlice";

const rootReducer = combineReducers({
  hc: hcReducer,
  user: userReducer,
});

/**
 * Initialize the Redux store
 * @param preloadedState - Override initial state, e.g. for testing
 * @returns The store
 */
export function initializeStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof initializeStore>;
export type AppDispatch = AppStore["dispatch"];

export default initializeStore;
