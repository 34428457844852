import { Box, Grid } from "@mui/material";
import { useGetPosts } from "wordpress";
import React from "react";
import { useGetCategories, useGetTags } from "wordpress";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import Link from "next/link";

/**
 * Represents props for the WordPress content widget
 */
export interface WordPressRelatedContentProps {
  /** A keyword that will be used to search for posts */
  keyword?: string;
  /** A list of the names of all selected tags */
  categories?: {
    categoryName: string;
  }[];
  /** A list of the names of all selected categories */
  tags?: {
    tagName: string;
  }[];
  /** The number of posts per page */
  pageSize: number;
  /** Whether to show featured images */
  showFeaturedImages: boolean;
  /** The title of the widget */
  widgetTitle: string;
  /** The default image that will be displayed if there is no featured image */
  defaultImageURL: string;
  /** The domain from which posts will be pulled */
  wordpressDomain: string;
}

export default function WordPressRelatedContent(
  props: WordPressRelatedContentProps
) {
  const allTags = useGetTags(props.wordpressDomain);
  const allCategories = useGetCategories(props.wordpressDomain);
  const selectedTagNames = props.tags
    ? props.tags.map((tag) => tag.tagName)
    : [];
  const selectedCategoryNames = props.categories
    ? props.categories.map((category) => category.categoryName)
    : [];

  const { data: posts, error } = useGetPosts({
    page: 1,
    pageSize: props.pageSize,
    keyword: props.keyword,
    tagIds: allTags.data
      ? allTags.data
          .filter((tag) => selectedTagNames.includes(tag.name))
          .map((tag) => tag.id)
      : [],
    categoryIds: allCategories.data
      ? allCategories.data
          .filter((category) => selectedCategoryNames.includes(category.name))
          .map((category) => category.id)
      : [],
    ready: allTags.data && allCategories.data ? true : false,
    wordpressDomain: props.wordpressDomain,
  });

  if (error) return <div>{error.message}</div>;

  return (
    <>
      <Box width="100%" border="1px solid #b3b3b3">
        <Box
          id="wordpress-header"
          sx={{
            backgroundColor: "#2F68CA",
            color: "#fff",
            paddingLeft: "40px",
            fontSize: "32px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            display: "flex",
            paddingTop: "5px",
            paddingBottom: "5px",
            marginBottom: "10px",
          }}
        >
          <NewspaperIcon
            sx={{
              marginRight: "20px",
              fontSize: "32px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
          <Box>{props.widgetTitle}</Box>
        </Box>
        <Grid container>
          {posts &&
            Array.from(posts).map((post) => {
              return (
                <Box
                  width="100%"
                  borderBottom="1px solid #b3b3b3"
                  key={post.id}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                    }}
                    xs={12}
                  >
                    <Grid item>
                      <Box
                        component="img"
                        height="100%"
                        width="auto"
                        sx={{
                          objectFit: "cover",
                          maxWidth: {
                            xs: "75px",
                            sm: "150px",
                          },
                        }}
                        display={props.showFeaturedImages ? "block" : "none"}
                        src={
                          post.featured_media_links?.large
                            ? post.featured_media_links.large
                            : props.defaultImageURL
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      paddingLeft="20px"
                      paddingTop="20px"
                      paddingBottom="20px"
                      xs={10}
                    >
                      <Box
                        component={Link}
                        sx={{
                          fontSize: {
                            xs: "14px",
                            sm: "18px",
                          },
                          fontWeight: 700,
                          fontFamily: "DM Sans, sans-serif",
                          paddingTop: "20px",
                          textDecoration: "none",
                          color: "#000",
                          "&:hover": {
                            textDecoration: "underline",
                            color: "#2F68CA",
                          },
                        }}
                        href={post.link}
                      >
                        {post.title.rendered}
                      </Box>
                      <Box
                        sx={{
                          fontSize: {
                            xs: "14px",
                            sm: "18px",
                          },
                          fontWeight: "normal",
                          fontFamily: "DM Sans, sans-serif",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: post.excerpt.rendered,
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "14px",
                          },
                          fontFamily: "DM Sans, sans-serif",
                          color: "#a3a3a3",
                        }}
                      >
                        {Date.parse(post.modified) > Date.parse(post.date)
                          ? `Updated on ${new Date(
                              Date.parse(post.modified)
                            ).toLocaleString("en-US")} by ${post.author_name}`
                          : `${new Date(Date.parse(post.date)).toLocaleString(
                              "en-US"
                            )} by ${post.author_name}`}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
        </Grid>
      </Box>
    </>
  );
}
