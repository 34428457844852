/** Represents a Tag to which posts can belong */
export interface WordPressTag {
  /** The tag ID */
  id: number;
  /** The number of posts associated with this tag */
  count: number;
  /** A description of the tag */
  description: string;
  /** A link to the tag */
  link: string;
  /** The name of the tag */
  name: string;
  /** The slug associated with the tag */
  slug: string;
}

/**
 * Pulls all the possible blog post categories from the WordPress API
 * @param tagsPerPage - The number of tags to pull per request - cannot exceed 100
 * @returns An array of all possible WordPress tags - see WordPressTag
 */
export async function GetTags(
  tagsPerPage: number = 100,
  wordpressDomain: string
): Promise<WordPressTag[]> {
  if (tagsPerPage > 100 || tagsPerPage < 1) {
    throw new TypeError(
      "The WordPress API supports tag page sizes of 1 to 100 inclusive.  If unsure, use 100."
    );
  }
  const urlRoot = wordpressDomain;
  if (!urlRoot) throw new TypeError("WordPress domain is required");

  // Create an array to hold all the tags
  let allTags: WordPressTag[] = [];

  async function fetchTags(page: number) {
    // Get tags for the given page number
    const response = await fetch(
      `${urlRoot}/tags?per_page=${tagsPerPage}&page=${page}`
    );
    if (!response.ok) {
      throw new Error("There was an error fetching WordPress tags");
    }
    const tags = (await response.json()) as WordPressTag[];
    // Add the tags to the array of all tags
    allTags = allTags.concat(tags);
    // If the array of tags is equal to the max page size, it means there are likely more tags
    // In this case, fetch the next page of tags
    // If the array of returned tabs is not the page size, it means we've reached the last page
    if (tags.length === tagsPerPage) {
      await fetchTags(page + 1);
    }
  }

  // Fetch the first page of tags, which will recursively fetch all subsequent pages
  await fetchTags(1);
  return allTags;
}
