import { useEffect, useState } from "react";
import { updateUser } from "../../state/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import useAmplitudeExperimentClient from "./useAmplitudeExperimentClient";

/**
 * This hook should be called once per page load to initialize the Amplitude Experiment SDK.
 * The hook gets the `ExperimentClient` instance associated with the `instanceName` and
 * then retrieves all remote configuration for the user, including experiments and variants.
 * Subsequent components and hooks can then retrieve specific variant data by calling the
 * `variant` method on the client.
 *
 * The Snowplow tracker must also be initialized using `usePageView` so that a domain user ID
 * is assigned and saved in the Redux store. If the domain user ID is not present, then the
 * hook cannot initialize the Amplitude experiment.
 *
 * @param instanceName - The name of the Amplitude `ExperimentClient` instance to use.
 * If not provided, the default name will be used (which is usually what you want).
 */
export function useAmplitudeStart(instanceName?: string) {
  const experiment = useAmplitudeExperimentClient(instanceName);
  const domainUserId = useAppSelector(({ user }) => user.domainUserId);
  const dispatch = useAppDispatch();
  const [started, setStarted] = useState(false);

  useEffect(() => {
    if (experiment && domainUserId && !started) {
      experiment
        .start({ user_id: domainUserId })
        .then(() => {
          const variants = experiment.all();
          dispatch(
            updateUser({ domainUserId, variants, variantsLoaded: true })
          );
        })
        .catch((error) => {
          // When something goes wrong loading the experiments,
          // set variantsLoaded to true so that the experiment components
          // can use the fallback values.
          console.error(
            "Error while loading Amplitude experiment data: ",
            error
          );
          dispatch(
            updateUser({ domainUserId, variantsLoaded: true, variants: {} })
          );
        });
      setStarted(true);
    }
  }, [experiment, domainUserId, dispatch, started]);
}

export default useAmplitudeStart;
