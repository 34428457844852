import { Variant } from "@amplitude/experiment-js-client";
import { useEffect, useState } from "react";
import useAmplitudeExperimentClient from "../../events/hooks/useAmplitudeExperimentClient";
import { useAppSelector } from "../../state/hooks";

/** Arguments to `useExperiment` */
export interface UseExperimentProps {
  /** The experiment key (called "Feature Flag" in Amplitude) for which to pull the user's variant */
  experimentKey: string;
}

/** Indicates whether the user's variant data is available yet. */
export interface VariantState {
  /** Whether the variant data has been loaded from Amplitude. */
  loaded: boolean;

  /**
   * The user's assigned variant for the experiment. Will be undefined in the following circumstances:
   * - loaded is false, indicating data is not available yet
   * - loaded is true, indicating that the variant data could not be retrieved (perhaps due to a network error)
   */
  variant?: Variant;
}

/**
 * Given an experiment key, get the user's assigned variant and trigger an exposure event.
 * @returns The user's variant data for the experiment. Will be undefined if it hasn't loaded yet or cannot be found.
 */
export default function useExperiment({
  experimentKey,
}: UseExperimentProps): VariantState {
  const experiment = useAmplitudeExperimentClient();
  const { variantsLoaded, variants: userVariants } = useAppSelector(
    ({ user }) => user
  );
  const [variant, setVariant] = useState<Variant | undefined>(undefined);

  useEffect(() => {
    if (experiment && userVariants && experimentKey && variantsLoaded) {
      const maybeVariant = userVariants[experimentKey];
      if (maybeVariant) {
        setVariant(userVariants[experimentKey]);
        experiment?.exposure(experimentKey);
      }
    }
  }, [experiment, experimentKey, userVariants, variantsLoaded]);

  return { loaded: variantsLoaded, variant };
}
