export const stmStates = [
  "Alabama",
  "Arkansas",
  "Arizona",
  "Delaware",
  "Florida",
  "Georgia",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Michigan",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  "North Dakota",
  "Nebraska",
  "Nevada",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "South Carolina",
  "Tennessee",
  "Texas",
  "Virginia",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
];

export const fbmStates = ["Hawaii", "Rhode Island", "South Dakota"];

export const salesStates = stmStates.concat(fbmStates);
