import Script from "next/script";

/** Props for the Microsoft/Bing tracker */
export interface MicrosoftBingProps {
  /** The URL of the Bing tracker */
  url: string;
}

/**
 * Returns the Microsoft/Bing PivotHealth tracking script
 * @param props - See MicrosoftProps
 * @returns The Microsoft/Bing tracking script
 */
export default function MicrosoftBing(props: MicrosoftBingProps) {
  const enabled = process.env.NEXT_PUBLIC_ENABLE_THIRD_PARTY_SCRIPTS === "true";

  if (!enabled) return <></>;

  return (
    <Script id="microsoft-bing">
      {`
        (function(w,d,t,r,u)
        {
          var f,n,i;
          w[u]=w[u]||[],f=function()
          {
            var o={ti:"14001554", enableAutoSpaTracking: true};
            o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
          },
          n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
          {
            var s=this.readyState;
            s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
          },
          i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
        })
        (window,document,"script","${props.url}","uetq");
        `}
    </Script>
  );
}
