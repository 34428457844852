import {
  AnyAction,
  CombinedState,
  Dispatch,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { HcState } from "../../state/features/hc/HcState";
import { updateLocation } from "../../state/features/hc/hcSlice";

/** An interface defining the options for the handleZipChange function */
export interface HandleZipChangeProps {
  /** A list of trimmed down locations from the GeoIP API */
  locations: {
    /** The name of the state */
    stateName?: string | null;
    /** The state's 2-letter abbreviation */
    stateAbbreviation?: string | null;
    /** The numerical state id */
    stateId?: number | null;
    /** The zip code */
    zip?: string | null;
    /** The city */
    city?: string | null;
    /** The county */
    countyName?: string | null;
  }[];
  setShowZipError: React.Dispatch<React.SetStateAction<boolean>>;
  setState?: React.Dispatch<React.SetStateAction<string>>;
  dispatch: ThunkDispatch<
    CombinedState<{ hc: HcState }>,
    undefined,
    AnyAction
  > &
    Dispatch<AnyAction>;
}

/**
 * Handles location data storage, state name visibility, and error message visibility given a user's location
 * If there are:
 *   0 locations  - This means the API didn't get anything back for the given zip code (invalid).  Display an error message.
 *   1 location   - Store the relevant location info in the state, set the name of the state, clear the error message.
 *   2+ locations -
 *     If the locations are in the same state, store the state & zip but not county and city. Return the state. Clear error.
 *     If the locations are not in the same state, store only the zip code. Do not return the state. Clear error.
 * @param options - See HandleZipChangeProps
 */
export default function handleZipChange(options: HandleZipChangeProps) {
  if (options.locations.length === 0) {
    options.setShowZipError(true);
    return;
  }

  const location = options.locations[0];

  if (options.locations.length === 1) {
    if (options.setState) options.setState(location.stateName || "");
    options.setShowZipError(false);
    options.dispatch(
      updateLocation({
        state: location.stateName || "",
        state_acronym: location.stateAbbreviation || "",
        zip_code: location.zip || "",
        city: location.city || "",
        county: location.countyName || "",
      })
    );
  } else {
    if (options.locations.length > 1) {
      // Map over the list of locations to generate an array of all the state IDs
      // The change to using state IDs rather than their acronyms was made in the name of performance
      const stateIdMap = options.locations.map((location) => location.stateId);

      // Determine whether all the locations are within the same state
      const allSameState = stateIdMap.every((id) => id === stateIdMap[0]);

      options.setShowZipError(false);
      if (options.setState) {
        if (allSameState && location.stateName)
          options.setState(location.stateName);
        else options.setState("");
      }
      options.dispatch(
        updateLocation({
          state: allSameState
            ? location.stateName
              ? location.stateName
              : ""
            : "",
          state_acronym: allSameState
            ? location.stateAbbreviation
              ? location.stateAbbreviation
              : ""
            : "",
          zip_code: location.zip || "",
          city: "",
          county: "",
        })
      );
    }
  }
}
