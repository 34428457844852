import type { ContextFilter } from "@snowplow/browser-tracker";
import type { PhChannelDataProvider } from "./PhChannelDataProvider";

/**
 * Determines whether the Pivot Health context should be used by Snowplow to
 * include some additional data in Snowplow events.
 * @param dataProvider - Returns Pivot Health sales channel data from a data source
 * @returns A function that returns `true` if the Pivot Health context should be used
 * and false if it should not.
 */
export default function phChannelContextFilter(
  dataProvider: PhChannelDataProvider
): ContextFilter {
  return () => {
    const data = dataProvider.readPhChannelContextData();
    return (
      data.agency_id != null && data.agent_id != null && data.channel != null
    );
  };
}
