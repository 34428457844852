import { useQuery, useLazyQuery } from "@apollo/client";
import { trackUserClick } from "@hc-frontend/third-party-snowplow";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MuiCheckIcon from "@mui/icons-material/Check";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { documents } from "dtc-graphql-schema";
import React from "react";
import { zipCodeSubmitEvent } from "../../events/snowplow-events";
import validateZipCode from "../../helpers/zipCode";
import { dtcTheme } from "../../theme/theme";
import { salesStates } from "../../helpers/salesStates";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import handleZipChange from "./handleZipChange";

/**
 * Renders a ZIP Code input widget and stores location data from the user, either based on their IP or based on what they enter
 */
export default function ZipCodeWidget() {
  const theme = useTheme();

  // Setup hooks for geolocation - call based on IP immediately, lazily execute based on zip code when the user inputs one
  const { error: locationError, data: locationData } = useQuery(
    documents.GET_LOCATION_FROM_USER_IP
  );
  const [locationFromZip, { loading }] = useLazyQuery(
    documents.GET_LOCATION_FROM_ZIP
  );

  const zipFromStorage = useAppSelector((state) => state.hc.location.zip_code);
  const dispatch = useAppDispatch();
  const [zipCode, setZipCode] = React.useState("");

  const [showState, setShowState] = React.useState(false);
  const [showZipError, setShowZipError] = React.useState(false);

  const [state, setState] = React.useState("");

  const [userHasInteracted, setUserHasInteracted] = React.useState(false);

  const [showBackdrop, setShowBackdrop] = React.useState(false);

  React.useEffect(() => {
    // If there is a zipCode in local storage, set the zip code to that value
    // Needs to be done in useEffect to avoid hydration errors
    setZipCode(zipFromStorage);

    async function loadLocation() {
      const locationResponse = await locationFromZip({
        variables: {
          zip: zipFromStorage,
        },
      });

      handleZipChange({
        locations: locationResponse.data
          ? locationResponse.data?.getLocationFromZip.map((location) => ({
              stateName: location.stateName,
              stateAbbreviation: location.stateAbbreviation,
              zip: location.zip,
              city: location.city,
              countyName: location.countyName,
              stateId: location.stateId,
            }))
          : /* c8 ignore next */
            [],
        setShowZipError: setShowZipError,
        setState: setState,
        dispatch: dispatch,
      });
    }

    if (zipFromStorage.length === 5) {
      loadLocation();
    }
  }, [dispatch, locationFromZip, zipFromStorage]);

  React.useEffect(() => {
    // Default to not showing the state that has been pulled from geolocation
    setShowState(false);
    // If the user's state is one of the ones PivotHealth sells to, set the flag to `true` so it shows in the widget
    // This doesn't fire if the IP Geolocation call is still loading
    if (
      salesStates.find((salesState) => salesState === state) &&
      zipCode.length === 5 &&
      !loading
    ) {
      setShowState(true);
    }
  }, [state, zipCode, loading]);

  React.useEffect(() => {
    if (!locationData) return;

    // If the user does not have a ZIP code (at all, meaning not entered or from GeoIP) set the zip code to the one from GeoIP
    // and store the location data from GeoIP on the session.
    // This should only ever run once, as once the user enters any data, the userHasInteracted flag will be set to `true`.
    // Without this flag, any time the ZIP code changes, it'll default back to the one from GeoIP (bad) even if the user is typing
    if (
      !locationError &&
      locationData.getLocationFromUserIp.length > 0 &&
      zipCode === "" &&
      !userHasInteracted
    ) {
      const location = locationData.getLocationFromUserIp[0];
      if (location.zip && location.zip.length >= 5) {
        setZipCode(location.zip);
        handleZipChange({
          locations: locationData.getLocationFromUserIp.map((location) => ({
            stateName: location.stateName,
            stateAbbreviation: location.stateAbbreviation,
            zip: location.zip,
            city: location.city,
            countyName: location.countyName,
          })),
          setShowZipError: setShowZipError,
          setState: setState,
          dispatch: dispatch,
        });
      }
    }
  }, [locationData, locationError, zipCode, userHasInteracted, dispatch]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setZipCode(e.target.value);
    // When a change event comes in from the ZIP code field...
    // Flag that the user has interacted with the widget, disabling GeoIP from overwriting what they type
    setUserHasInteracted(true);
    // Do not show the "in (State)" message - it should only show after location via zip and validation
    setShowState(false);

    // Load the user's location from their zip code
    async function loadLocation() {
      const locationResponse = await locationFromZip({
        variables: {
          zip: e.target.value,
        },
      });

      const locations = locationResponse.data
        ? locationResponse.data?.getLocationFromZip.map((location) => ({
            stateName: location.stateName,
            stateAbbreviation: location.stateAbbreviation,
            zip: location.zip,
            city: location.city,
            countyName: location.countyName,
            stateId: location.stateId,
          }))
        : /* c8 ignore next */
          [];

      handleZipChange({
        locations: locations,
        setShowZipError: setShowZipError,
        setState: setState,
        dispatch: dispatch,
      });
    }

    // Only attempt to zip-locate the user if the zip code they've entered is 5 characters
    if (e.target.value.length === 5) {
      loadLocation();
    }
  };

  return (
    <Box>
      <Typography
        component="div"
        fontFamily="proxima-nova; sans-serif"
        fontWeight={600}
        fontSize="20px"
        color="#fff"
        display="block"
        marginBottom="10px"
      >
        A Budget-Friendly Option to Reduce Your Monthly Costs
        <Typography
          component="div"
          fontFamily="proxima-nova; sans-serif"
          fontWeight={600}
          fontSize="20px"
          color={theme.palette.tertiary.dark}
          display="inline"
        >
          {state && !showZipError && showState && ` in ${state}`}
        </Typography>
      </Typography>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "4px",
          padding: "20px",
          paddingTop: "5px",
          paddingBottom: "5px",
          zIndex: 5,
          position: "relative",
        }}
      >
        <Typography
          sx={{
            color: "#343a40",
            fontSize: "26px",
            textAlign: "center",
            fontFamily: "proxima-nova, sans-serif",
            fontWeight: 400,
          }}
        >
          Start by Adding Your ZIP Code
        </Typography>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6}>
            <TextField
              variant="filled"
              fullWidth
              placeholder="Enter Zip"
              type="text"
              onChange={handleChange}
              value={zipCode}
              onFocus={() => setShowBackdrop(true)}
              onBlur={() => setShowBackdrop(false)}
              InputProps={{
                disableUnderline: true,
                sx: {
                  backgroundColor: "#ecf7ff",
                  boxShadow: "0 2px 4px rgba(0,0,0,.170209)",
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      backgroundColor: "#ecf7ff",
                    }}
                  >
                    {zipCode.length === 5 && !showZipError && (
                      <MuiCheckIcon
                        sx={{
                          backgroundColor: "#ecf7ff",
                          color: dtcTheme.palette.success.main,
                          fontWeight: "800",
                          stroke: dtcTheme.palette.success.main,
                          strokeWidth: "1",
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                inputMode: "decimal",
                maxLength: 5,
                style: {
                  backgroundColor: "#ecf7ff",
                  fontSize: "26px",
                  paddingTop: "8px",
                  fontFamily: "proxima-nova, sans-serif",
                  fontWeight: 600,
                  color: "#a9a9a9",
                  width: "40%",
                  marginLeft: "30%",
                  marginRight: "auto",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                if (validateZipCode(zipCode) && !showZipError) {
                  trackUserClick(zipCodeSubmitEvent(zipCode));
                  window.open(`/census/?zip=${zipCode}`, "__blank");
                  window.location.assign(`/fas/?zip=${zipCode}`);
                } else {
                  setShowZipError(true);
                }
              }}
            >
              See Our Plans
              <ArrowForwardIosIcon sx={{ marginLeft: "20px" }} />
            </Button>
          </Grid>
        </Grid>
        {showZipError && (
          <Box>
            <Typography
              color="error"
              fontFamily="proxima-nova, sans-serif"
              fontWeight={400}
              fontSize="12px"
            >
              Please enter a valid 5-digit ZIP code.
            </Typography>
          </Box>
        )}

        <Typography
          component="div"
          sx={{
            fontSize: "18px",
            fontWeight: 400,
            textAlign: "center",
            fontFamily: "proxima-nova, sans-serif",
            padding: "10px",
          }}
        >
          Join the thousands of members we&apos;ve covered
          <Typography
            component="p"
            sx={{
              fontSize: "18px",
              fontWeight: 400,
              textAlign: "center",
              fontFamily: "proxima-nova, sans-serif",
            }}
            display="inline"
            data-testid="in-state-text"
          >
            {state && !showZipError && showState && ` in ${state}`}
          </Typography>
        </Typography>
      </Box>
      <Backdrop
        sx={{
          zIndex: 2,
          backgroundColor: "rgba(0,0,0,0.75)",
        }}
        transitionDuration={150}
        open={showBackdrop}
      />
    </Box>
  );
}
