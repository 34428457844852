import React from "react";
export default function CallerImage() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" fill="none">
      <path
        fill="#FED047"
        d="M54 54H0v-2.377a4.738 4.738 0 012.888-4.366L17.6 41h18.8l14.712 6.257a4.738 4.738 0 012.887 4.366L54 54z"
      ></path>
      <path
        fill="#FFE461"
        d="M51.112 47.257L36.4 41H27v13h27v-2.377a4.738 4.738 0 00-2.888-4.366z"
      ></path>
      <path
        fill="#694B4B"
        d="M41.4 18H12.6c-.883 0-1.6-.677-1.6-1.511v-.523c0-4.865 1.066-8.514 3.26-11.154C16.912 1.619 21.198 0 27 0c5.802 0 10.089 1.619 12.74 4.812C41.935 7.452 43 11.1 43 15.966v.523c0 .834-.716 1.511-1.6 1.511z"
      ></path>
      <path
        fill="#5A4146"
        d="M41.4 18c.884 0 1.6-.677 1.6-1.511v-.523c0-4.865-1.066-8.514-3.26-11.154C37.09 1.619 32.803 0 27 0v18h14.4z"
      ></path>
      <path
        fill="#0E70B7"
        d="M17 32h-4.95C8.714 32 6 29.445 6 26.305v-4.61C6 18.555 8.714 16 12.05 16H17v16zm24.95 0H37V16h4.95c3.336 0 6.05 2.555 6.05 5.695v4.61C48 29.445 45.286 32 41.95 32z"
      ></path>
      <path
        fill="#DDB897"
        d="M17.003 35v5.222S16.544 51 27.5 51s10.497-10.778 10.497-10.778V35H17.003z"
      ></path>
      <path
        fill="#E5C7A8"
        d="M36.997 35H27v16c10.434 0 9.997-10.777 9.997-10.777V35z"
      ></path>
      <path
        fill="#E5C7A8"
        d="M27 44c-7.168 0-13-5.81-13-12.952V18.163c0-3.909 1.17-6.626 3.474-8.075 3.308-2.08 7.816-.658 9.526.012 1.71-.67 6.219-2.092 9.526-.012C38.831 11.538 40 14.255 40 18.163v12.885C40 38.19 34.168 44 27 44z"
      ></path>
      <path
        fill="#DDB897"
        d="M36.526 10.088C33.219 8.008 28.71 9.43 27 10.1V44c7.168 0 13-5.81 13-12.952V18.163c0-3.908-1.169-6.626-3.474-8.075z"
      ></path>
      <path
        fill="#38ACFF"
        d="M30.45 37h-1.863C27.71 37 27 36.307 27 35.451c0-.855.71-1.549 1.587-1.549h1.863a5.902 5.902 0 003.212-.943l6.837-4.676a1.612 1.612 0 012.212.374c.504.7.332 1.665-.384 2.158l-6.856 4.688c-.01.008-.022.015-.033.022A9.12 9.12 0 0130.45 37z"
      ></path>
    </svg>
  );
}
