import React from "react";
import { WordPressTag, GetTags } from "./GetTags";

/**
 * A hook wrapper for the GetTags function, for ease of use in React
 * @returns All WordPress tags
 */
export function useGetTags(wordpressDomain: string) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<WordPressTag[] | null>(null);
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    // Set loading flag to true
    setLoading(true);

    // Get the tags
    GetTags(100, wordpressDomain)
      .then((tags) => {
        setData(tags);
        setError(null);
      })
      .catch((error) => {
        setData(null);
        setError(error);
      })
      .finally(() => {
        // Set the loading flag to false
        setLoading(false);
      });
  }, []);

  return {
    loading: loading,
    data: data,
    error: error,
  };
}
