import { VariantKV } from "./ExperimentComponents.types";

/**
 * Get header content to display for a variant.
 *
 * @param variants - The variant data configured in Builder.io
 * @param defaultVariant - The variant to use if the user's assigned variant cannot be determined.
 * @param variantValue - The user's assigned variant.
 */
export default function getExperimentHeaderContent(
  variants: VariantKV[],
  defaultVariant: string,
  variantValue?: string
): string {
  let userVariant: VariantKV | undefined = undefined;
  if (variantValue) {
    userVariant = variants.find(
      (variant) => variant.variantKey === variantValue
    );
  }

  if (!userVariant) {
    userVariant = variants.find(
      (variant) => variant.variantKey === defaultVariant
    );
  }

  return userVariant?.variantContent ?? "";
}
