import { gql } from "../generated/client";

export const GET_LOCATION_FROM_ZIP = gql(/* GraphQL */ `
  query GetLocationFromZip($zip: String!) {
    getLocationFromZip(zip: $zip) {
      ip
      country
      countryName
      stateId
      stateName
      stateAbbreviation
      city
      zip
      latitude
      longitude
      countyId
      countyName
      fips
      ssa
      displayName
    }
  }
`);
