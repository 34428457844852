import { Typography } from "@mui/material";
import React from "react";

export interface BuilderDefaultComponentProps {
  text: string;
}

export default function BuilderH1(props: BuilderDefaultComponentProps) {
  return (
    <Typography
      component="h1"
      sx={{
        all: "unset",
      }}
    >
      {props.text}
    </Typography>
  );
}
