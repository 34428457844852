import { Box, Button, Collapse, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import React from "react";
import { trackUserClick } from "@hc-frontend/third-party-snowplow";
import { buttonBoxClickEvent } from "../events/snowplow-events";

/** Props for the ButtonBox widget */
export interface ButtonBoxProps {
  /** The URL of the image to be displayed in the ButtonBox */
  imageUrl: string;
  /** Alt text for the image */
  imageAltText: string;
  /** The text content to be displayed int he ButtonBox */
  content: string;
  /** The URL that the button should redirect to when clicked */
  buttonUrl: string;
  /** The text to display on the button */
  buttonText: string;
}

/**
 * A widget containing text and an image that expands when hovered/clicked to show a button
 * @param props - See ButtonBoxProps
 */
export default function ButtonBox(props: ButtonBoxProps) {
  const [showButton, setShowButton] = React.useState(false);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowButton(false);
      }}
    >
      <Box
        textAlign="center"
        border="1px solid #59baff"
        borderRadius="4px"
        boxShadow="none"
        paddingLeft="20px"
        paddingRight="20px"
        minHeight="100%"
        width="100%"
        onMouseOver={() => {
          setShowButton(true);
        }}
        onMouseOut={() => {
          setShowButton(false);
        }}
        onClick={() => {
          setShowButton(true);
        }}
      >
        <Box
          component="img"
          src={props.imageUrl}
          alt={props.imageAltText}
          marginTop="8px"
          sx={{
            height: {
              xs: "60px",
              sm: "60px",
              md: "60px",
              lg: "85px",
            },
          }}
        />
        <Typography
          fontFamily="proxima-nova, sans-serif"
          fontWeight={400}
          color="#4a4a4a"
          marginBottom="10px"
          sx={{
            fontSize: {
              xs: "14px",
              sm: "14px",
              md: "14px",
              lg: "20px",
            },
          }}
        >
          {props.content}
        </Typography>
        <Collapse in={showButton} role="collapse">
          <Button
            fullWidth
            onClick={() => {
              trackUserClick(
                buttonBoxClickEvent({
                  value: props.buttonText,
                  targetUrl: props.buttonUrl,
                })
              );
              window.location.assign(props.buttonUrl);
            }}
            sx={{
              minHeight: "37px",
              height: "37px",
              color: "#fff",
              fontSize: "14px",
              marginBottom: "7px",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {props.buttonText}
            <ChevronRightIcon />
          </Button>
        </Collapse>
      </Box>
    </ClickAwayListener>
  );
}
