import { Box, Grid, Select } from "@mui/material";
import React from "react";

/** Props for the HeroDropdown component */
export interface HeroDropdownProps {
  /** A list of options to be displayed */
  options: {
    /** The URL to direct to if the option is selected */
    url: string;
    /** The label to be displayed for the given URL */
    label: string;
  }[];
  /** The text for the button */
  buttonText: string;
  /** The text for the CTA that appears left of the dropdown */
  ctaText: string;
}

/**
 * Represents the dropdown that displays in the hero section of the www homepage
 * @param props - See HeroDropdownProps
 * @returns A dropdown allowing users to select their role along with a button directing them
 *   to the desired starting page for their role
 */
export default function HeroDropdown(props: HeroDropdownProps) {
  const [selectedUrl, setSelectedUrl] = React.useState(
    props.options[0] ? props.options[0].url : null
  );

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          fontFamily: "DM Sans, sans-serif",
        }}
      >
        <Grid item xs={12} md={7}>
          <Grid
            container
            spacing={2}
            component={Box}
            sx={{
              boxShadow: "0 0 30px 0 rgba(0,0,0,0.1)",
              paddingTop: "10px",
              paddingBottom: "15px",
              borderRadius: "105px",
            }}
          >
            <Grid
              item
              xs={8}
              sx={{
                textAlign: "center",
                color: "#303a52c2",
              }}
            >
              {props.ctaText}
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                borderLeft: "1px solid rgba(0,0,0, 0.4)",
                paddingTop: "0px !important",
                paddingRight: "5px",
                textAlign: "center",
                color: "#303a52c2",
              }}
            >
              <Select
                native
                defaultValue={props.options[0] ? props.options[0].url : null}
                disableUnderline
                variant="standard"
                inputProps={{
                  "data-testid": "hero-dropdown-select",
                }}
                sx={{
                  marginTop: "3px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontFamily: "DM Sans, sans-serif",
                  color: "#303a52c2",
                  "&:focus": {
                    backgroundColor: "#fff",
                  },
                }}
                onChange={(e) => {
                  setSelectedUrl(e.target.value);
                }}
              >
                {props.options.map((option, index) => {
                  return (
                    <option key={index} value={option.url}>
                      {option.label}
                    </option>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            paddingTop: "0px !important",
            paddingLeft: "0px !important",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              borderRadius: "105px",
              textAlign: "center",
              background:
                "linear-gradient(245deg,#006DBB -15.63%,#0086E7 89.53%)",
              color: "#fff",
              paddingTop: "15px",
              paddingBottom: "15px",
              boxShadow: "0 0 25px 0 rgba(0,104,180,0.2)",
              fontFamily: "DM Sans, sans-serif",
              fontSize: "18px",
              fontWeight: 400,
              width: {
                xs: "100%",
                md: "80%",
              },
              marginTop: {
                xs: "10px",
                md: "0px",
              },
              margin: "auto",
            }}
            onClick={() => {
              if (window && selectedUrl) window.location.assign(selectedUrl);
            }}
          >
            {props.buttonText}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
