import { Collapse, Divider, Grid, Typography, useTheme } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import React from "react";
import { trackUserClick } from "@hc-frontend/third-party-snowplow";
import { closeExpandoEvent, openExpandoEvent } from "../events/snowplow-events";

/**
 * Props for the Expando component
 * @param title - The title content that will always be displayed when the Expando is rendered
 * @param content - The HTML contents that will be displayed when the Expando is opened
 * @param defaultExpanded - Whether or not the expando should be open by default
 */
export interface ExpandoProps {
  title: string;
  content: string; // HTML
  defaultExpanded: boolean;
}

/**
 * An expandable content section that display a title and (+) icon. When either is clicked, expand below to show more content
 * @param props - See ExpandoProps
 */
export default function Expando(props: ExpandoProps) {
  const [isExpanded, setIsExpanded] = React.useState(props.defaultExpanded);

  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={1}
          onClick={() => {
            if (isExpanded) trackUserClick(closeExpandoEvent(props.title));
            else trackUserClick(openExpandoEvent(props.title));
            setIsExpanded(!isExpanded);
          }}
          style={{ textAlign: "center" }}
        >
          {isExpanded ? (
            <RemoveCircleIcon
              sx={{ color: theme.palette.tertiary.dark, cursor: "pointer" }}
            />
          ) : (
            <AddCircleIcon
              sx={{ color: theme.palette.tertiary.dark, cursor: "pointer" }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={11}
          onClick={() => {
            if (isExpanded) trackUserClick(closeExpandoEvent(props.title));
            else trackUserClick(openExpandoEvent(props.title));
            setIsExpanded(!isExpanded);
          }}
        >
          <Typography
            variant="h5"
            fontSize="18px"
            fontWeight={600}
            sx={{
              cursor: "pointer",
            }}
          >
            {props.title}
          </Typography>
        </Grid>
      </Grid>
      <Collapse in={isExpanded} role="content">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={1} />
          <Grid item xs={11}>
            <Typography
              component="div"
              sx={{
                fontFamily: "proxima-nova, sans-serif",
                fontWeight: 400,
                fontSize: "18px",
              }}
              dangerouslySetInnerHTML={{ __html: props.content }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
}
