import Script from "next/script";

/** Returns the OptinMonster account-wide embed script */
export default function OptinMonster() {
  const enabled = process.env.NEXT_PUBLIC_ENABLE_THIRD_PARTY_SCRIPTS === "true";

  if (!enabled) return <></>;

  return (
    <Script id="optinmonster-script">
      {`
        const startOptinMonster = function(d,u,ac) {
          var s=d.createElement('script');
          s.type='text/javascript';
          s.src='https://a.omappapi.com/app/js/api.min.js';
          s.async=true;
          s.dataset.user=u;
          s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);
        };

        startOptinMonster(document, 82111, 92389);

      `}
    </Script>
  );
}
