import { BuilderBlocks } from "@builder.io/react";
import { Box, ClickAwayListener, Link as MUILink } from "@mui/material";
// import Image from "next/image";
import Link from "next/link";
import React from "react";
import { HeaderProps } from "./Header";

export default function HeaderDesktop(props: HeaderProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(props.tabs[0]);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <MUILink component={Link} href="/" data-testid="header-logo">
            <Box
              component="img"
              height="36"
              width="160"
              alt="Pivot Health Logo"
              src="https://cdn.builder.io/api/v1/image/assets%2Fc9fcfa3fdc8d44d2a2c2522da436aabc%2Fecb7c86153ed4fb7ae1ba9e28998fbec?width=160"
            />
          </MUILink>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-evenly",
            }}
          >
            <Box
              component={Link}
              href="https://www.pivothealth.com/product-portfolio"
              data-testid="header-cta"
              sx={{
                border: "1px solid #303a52",
                borderRadius: "105px",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                paddingLeft: "5px",
                paddingRight: "5px",
                cursor: "pointer",
                textDecoration: "none",
                color: "#303a52",
                transition: "background-color 0.15s ease-in-out",
                "&:hover": {
                  backgroundColor: "#ffc057",
                },
              }}
            >
              Find Your Plan
            </Box>
            <Box
              sx={{
                marginTop: "6px",
                cursor: "pointer",
                display: "flex",
              }}
            >
              <MUILink
                component={Link}
                data-testid="header-phone-link"
                href="tel:8772460106"
              >
                <Box
                  component="img"
                  alt="Phone Icon"
                  src="https://cdn.builder.io/api/v1/image/assets%2Fc9fcfa3fdc8d44d2a2c2522da436aabc%2F13353a2ac884427b8cbb97b654a1c143?width=16"
                  width="16"
                  height="20"
                  style={{
                    marginTop: "2px",
                    marginRight: "5px",
                  }}
                />
              </MUILink>
              <MUILink
                component={Link}
                href="tel:8772460106"
                sx={{
                  textDecoration: "none",
                  color: "#303a52",
                }}
              >
                <Box data-testid="header-phone-number">877-246-0106</Box>
              </MUILink>
            </Box>
            {props.tabs.map((tab) => {
              return (
                <Box
                  key={tab.label}
                  sx={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    cursor: "pointer",
                    display: "flex",
                    transition: "color 0.15s ease-in-out",
                    "&:hover": {
                      color: "#ffc057",
                    },
                  }}
                  onClick={() => {
                    if (activeTab.label === tab.label && isOpen)
                      setIsOpen(false);
                    else if (tab.isExpandable) {
                      setIsOpen(true);
                      setActiveTab(tab);
                    }
                  }}
                >
                  {tab.isExpandable ? (
                    <Box sx={{ display: "flex" }}>
                      {" "}
                      {tab.label}{" "}
                      {tab.label === activeTab.label && isOpen
                        ? DownArrow()
                        : UpArrow()}{" "}
                    </Box>
                  ) : (
                    <MUILink
                      sx={{
                        textDecoration: "none",
                        color: "#303a52",
                        transition: "color 0.15s ease-in-out",
                        "&:hover": {
                          color: "#ffc057",
                        },
                      }}
                      href={tab.linkURL ? tab.linkURL : "#"}
                      component={tab.externalLink ? "a" : Link}
                      data-testid={tab.label}
                    >
                      {" "}
                      {tab.label}{" "}
                    </MUILink>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: props.backgroundColor,
            borderRadius: "12px",
            marginTop: "10px",
            zIndex: 999,
            position: "absolute",
            width: "100%",
            display: isOpen ? "block" : "none",
          }}
        >
          <BuilderBlocks
            /* c8 ignore next 1 */
            parentElementId={props.builderBlock?.id}
            dataPath={`component.options.tabs.${props.tabs.findIndex(
              (tab) => tab.label === activeTab.label
            )}.content`}
            blocks={activeTab.content}
          />
        </Box>
      </Box>
    </ClickAwayListener>
  );
}

export const UpArrow = (width: string = "14px", marginTop: string = "2px") => {
  return (
    <Box width={width} marginTop={marginTop}>
      <svg
        className="h-8 w-8 text-slate-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 15l7-7 7 7"
        />
      </svg>
    </Box>
  );
};

export const DownArrow = (
  width: string = "14px",
  marginTop: string = "2px"
) => {
  return (
    <Box width={width} marginTop={marginTop}>
      <svg
        className="h-8 w-8 text-slate-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </Box>
  );
};
