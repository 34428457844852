import React from "react";
import { useAppSelector } from "../state/hooks";
import {
  Box,
  Button,
  Fade,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ConnectMe from "./ConnectMe";
import { useMutation } from "@apollo/client";
import { documents } from "dtc-graphql-schema";
import CallerImage from "./CallerImage";
import useSalesCenterAvailable from "../helpers/useSalesCenterAvailable";

/** Props for the BeforeYouGoModal */
export interface BeforeYouGoModalProps {
  /** A preset phone number, formatted as XXX-XXX-XXXX */
  presetPhoneNumberFormatted?: string;
}

/** Renders the BeforeYouGo modal */
export default function BeforeYouGoModal(props: BeforeYouGoModalProps) {
  const showModal = process.env["NEXT_PUBLIC_SHOW_BYG_MODAL"] === "true";

  const [modalOpen, setModalOpen] = React.useState(showModal);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [hasClickedConnect, setHasClickedConnect] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [showPhoneError, setShowPhoneError] = React.useState(false);
  const available = useSalesCenterAvailable();

  // Pull relevant data for ConnectMe from state
  const destinationPhone = useAppSelector(
    (state) => state.hc.invoca_phone.promo_number_formatted
  );

  // Pull relevant data for lead audit request from state
  const members = useAppSelector((state) => state.hc.members);
  const contact = useAppSelector((state) => state.hc.contact);
  const location = useAppSelector((state) => state.hc.location);
  const leadId = useAppSelector((state) => state.hc.leadid);

  /* c8 ignore next */
  const moduleId = parseInt(
    process.env["NEXT_PUBLIC_CONNECT_ME_MODULE_ID"] || ""
  );

  const [leadAudit, { called }] = useMutation(documents.LEAD_AUDIT);

  React.useEffect(() => {
    const auditLead = async () => {
      try {
        await leadAudit({
          variables: {
            payload: {
              f_name: applicant?.firstName || "",
              l_name: applicant?.lastName || "",
              email: contact.email_address || "",
              phone1: phoneNumber,
              address1: contact.address || "",
              city: location.city || "",
              state: location.state || "",
              zip: location.zip_code || "",
              tcpa_universal_id: leadId.universal_leadid || "",
              dob: applicant?.stringDOB || "",
              gender: applicant?.gender || "",
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    };

    const applicant = members.find(
      (member) => member.type.toLowerCase() === "primary"
    );

    if (hasSubmitted && !called) {
      auditLead();
    }
  }, [
    called,
    contact,
    members,
    location,
    hasSubmitted,
    leadAudit,
    phoneNumber,
    leadId,
  ]);

  if (available) {
    return (
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0,0,0,0.75)",
            },
            role: "backdrop",
          },
        }}
        disableAutoFocus
      >
        <Fade in={modalOpen}>
          <Box width="30%" height="1vh">
            {/** Blue top section */}
            <Box
              width="100%"
              sx={{
                backgroundColor: "#0e70b7",
              }}
              padding="20px"
            >
              <Box
                component="img"
                src="https://enroll.pivothealth.com/assets/5e956e63fc04253ff4823134cbbdec74.webp"
                maxWidth="200px"
              />
              <Typography
                fontSize="60px"
                fontFamily="proxima-nova,sans-serif"
                color="#fff"
                fontWeight={700}
                lineHeight={1}
              >
                Hey don&apos;t wait till tomorrow!
              </Typography>
              <Typography
                fontSize="30px"
                fontFamily="proxima-nova,sans-serif"
                color="#fff"
                fontWeight={400}
                lineHeight={1}
              >
                Let us help you. Would you like to connect with one of our
                expert healthcare advisors?
              </Typography>
            </Box>
            {/** White interactive section */}
            <Box
              width="100%"
              height="100vh"
              sx={{
                backgroundColor: "#fff",
              }}
              padding="40px"
            >
              {hasClickedConnect && !hasSubmitted && (
                <Tooltip
                  open={showPhoneError}
                  placement="bottom-start"
                  title="This field is required. Please fill it with valid information."
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: "#eb5e53",
                        fontFamily: "proxima-nova, sans-serif",
                        fontWeight: "400",
                        fontSize: "14px",
                        border: "1px solid #eb5e53",
                        backgroundColor: "#fdefee",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "#fdefee",
                        "&::before": {
                          border: "1px solid #eb5e53",
                          boxSizing: "border-box",
                        },
                      },
                    },
                  }}
                  arrow
                >
                  <TextField
                    fullWidth
                    placeholder="Enter your phone number here for a free call-back"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      setShowPhoneError(false);
                    }}
                    sx={{
                      "&:hover": {
                        borderRight: "none",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      },
                      marginBottom: "20px",
                    }}
                    inputProps={{
                      maxLength: 10,
                      style: {},
                    }}
                    InputProps={{
                      style: {
                        height: "43px",
                        borderRight: "none",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        fontFamily: "proxima-nova, sans-serif",
                        backgroundColor: showPhoneError ? "#f45a5c1a" : "#fff",
                      },
                    }}
                  />
                </Tooltip>
              )}
              {!hasSubmitted && (
                <>
                  <Button
                    fullWidth
                    onClick={() => {
                      // If the user is clicking the button for the first time, show the phone field
                      if (!hasClickedConnect) setHasClickedConnect(true);
                      else {
                        // If the user has already clicked the button for the phone number field, validate and start ConnectMe
                        if (phoneNumber.length === 10) setHasSubmitted(true);
                        else setShowPhoneError(true);
                      }
                    }}
                    sx={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontFamily: "proxima-nova,sans-serif",
                      backgroundColor: "#f9690e",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#f97d0e",
                      },
                    }}
                  >
                    Connect Me To An Advisor
                  </Button>
                </>
              )}
              {hasClickedConnect && !hasSubmitted && (
                <Typography color="#b8b8b8" fontSize="12px" marginTop="20px">
                  Let our agents assist you! We&apos;ll never share your
                  information and only contact you about your coverage. By
                  submitting your information and clicking above, you represent
                  that you are at least 18 years old and agree to be contacted
                  by Pivot Health LLC and its affiliates (expressly including
                  Healthcare, Inc. and Healthcare.com Insurance Services, LLC)
                  at the number you provided (including any wireless number)
                  regarding various insurance products, through the use of
                  automated telephone dialing systems, pre-recorded messages,
                  and/or text messages. Message and data rates may apply. Your
                  consent is not a condition of purchase. This program is
                  subject to our Privacy Policy and Terms of Use.
                </Typography>
              )}
              {hasSubmitted && (
                <Typography
                  component="div"
                  fontFamily="proxima-nova,sans-serif"
                >
                  <Box textAlign="center" margin="auto" width="100%">
                    <CallerImage />
                  </Box>
                  <ConnectMe
                    userPhoneNumber={phoneNumber}
                    /* c8 ignore next 4 */
                    invocaPhoneNumber={
                      props.presetPhoneNumberFormatted
                        ? props.presetPhoneNumberFormatted
                        : destinationPhone
                    }
                    moduleId={moduleId}
                    conversionId=""
                  />
                </Typography>
              )}
              <Typography
                onClick={() => setModalOpen(false)}
                color="#989898"
                fontFamily="proxima-nova,sans-serif"
                fontSize="20px"
                sx={{
                  textDecoration: "underline",
                  marginTop: "20px",
                }}
              >
                No thanks, I want to keep exploring
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  }
  return <></>;
}
