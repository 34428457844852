import { trackUserClick } from "@hc-frontend/third-party-snowplow";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, Slide, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  closePreviousQuoteBannerEvent,
  viewPreviousQuoteEvent,
} from "../events/snowplow-events";
import { MemberType } from "../state/features/hc/Member";
import { useAppSelector } from "../state/hooks";

export const DISMISS_BANNER_SESSION_KEY = "ph_welcome_dismissed";

/**
 * Renders the Previous Quote Banner, allowing a user to skip census and go back to their previously generated quotes.
 *
 * The banner determines whether the user has quotes or not by examining the `hc` object in the Redux store.
 * This object is derived from 1) `localStorage` and 2) the session management API. Specifically, if the
 * `members` array in the `hc` object contains a member with type "Applicant," and the applicant has a DOB
 * and a gender, then we will render the banner.
 *
 * There is one additional condition to consider: if the user closes the banner, then we set a flag in session storage
 * indicating that the user has dismissed the banner and that it should be hidden on future page loads.
 */
export default function PreviousQuoteBanner() {
  const members = useAppSelector((state) => state.hc.members);
  const [showBanner, setShowBanner] = React.useState(false);

  useEffect(() => {
    // If the user has previously dismissed the banner, then there should be a flag in storage
    const dismissed = JSON.parse(
      sessionStorage.getItem(DISMISS_BANNER_SESSION_KEY) || "null"
    ) as boolean | null;

    if (dismissed) {
      setShowBanner(false);
      return;
    }

    // If there's no flag in storage, then we can show the banner IF the user has
    // already entered their DOB and gender.
    const applicant = members.find(
      (member) => member.type === MemberType.Applicant
    );
    if (applicant && applicant.dob && applicant.gender) {
      setShowBanner(true);
    }
  }, [showBanner, setShowBanner, members]);

  /** Runs when the user clicks the close button on the banner. */
  const dismissBanner = () => {
    trackUserClick(closePreviousQuoteBannerEvent);
    sessionStorage.setItem(DISMISS_BANNER_SESSION_KEY, "true");
    setShowBanner(false);
  };

  return (
    <Slide direction="right" in={showBanner} unmountOnExit>
      <Box
        sx={{
          background: "linear-gradient(.628turn,#0086e7,#0e70b7)",
          paddingTop: "8px",
          paddingBottom: "8px",
          width: "100%",
          ["@media (max-width: 768px)"]: {
            paddingTop: "8px",
            paddingBottom: "8px",
          },
        }}
      >
        <Box
          sx={{
            margin: "auto",
            maxWidth: "1200px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Box maxWidth="100%" margin="auto">
            <Grid container spacing={0} display="flex" alignItems="center">
              <Grid item xs={7} sm={7}>
                <Typography
                  variant="h3"
                  fontSize="28px"
                  fontWeight="800"
                  fontFamily="DM Sans, sans-serif"
                  color="#fff"
                  sx={{
                    ["@media (max-width: 992px)"]: {
                      fontSize: "18px",
                    },
                  }}
                >
                  Good News! We Found Your Previous Quote
                </Typography>
              </Grid>
              <Grid item xs={5} sm={5}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  sx={{
                    width: "100%",
                    ["@media (max-width: 1233px)"]: {
                      width: "90%",
                    },
                  }}
                >
                  <Button
                    size="large"
                    onClick={() => {
                      trackUserClick(viewPreviousQuoteEvent);
                      window.location.assign("/quotes");
                    }}
                    sx={{
                      minHeight: "42px",
                      color: "#303a52",
                      fontSize: "18px",
                      fontFamily: "DM Sans, sans-serif",
                      backgroundColor: "#ffa816",
                      borderRadius: "105px",
                      padding: ".5rem 1rem",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "#ffc057",
                      },
                      ["@media (max-width: 611px)"]: {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Your Quote Results
                  </Button>
                  <CloseIcon
                    sx={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      color: "#fff",
                    }}
                    onClick={dismissBanner}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Slide>
  );
}
