import React from "react";
import MobileHomepageAccordion from "./MobileHomepageAccordion";
import { Box } from "@mui/material";

interface MobileHomepageAccordionPropsBare {
  title: string;
  content?: string;
  learnMoreLink?: string;
  color: string;
  image: string;
}

export interface MobileHomepageAccordionGroupProps {
  items: MobileHomepageAccordionPropsBare[];
}

export default function MobileHomepageAccordionGroup(
  props: MobileHomepageAccordionGroupProps
) {
  const [items, setItems] = React.useState(
    props.items.map((item) => ({ ...item, open: false }))
  );

  function setOpen(title: string, open: boolean) {
    const unchangedItems = items;
    const allFalse = unchangedItems.map((item) => ({ ...item, open: false }));
    const updatedItems = allFalse.map((item) => {
      if (item.title === title) {
        item.open = open;
      }
      return item;
    });

    setItems(updatedItems);
  }

  return (
    <Box
      sx={{
        width: "100%",
        margin: "auto",
      }}
    >
      {items.map((item) => {
        return (
          <MobileHomepageAccordion
            key={item.title}
            {...item}
            setOpen={setOpen}
          />
        );
      })}
    </Box>
  );
}
