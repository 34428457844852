import type { ContextEvent } from "@snowplow/browser-tracker";
import { getHcStorage } from "../state/features/hc/useHcStorage";

/** Pivot Health sales channel data to track in Snowplow. */
export interface PhChannelData {
  // Originally, these were the possible values for "channel,"
  // but it's not clear a) whether these are still accurate
  // and relevant and b) how to enforce this in a type-safe way.
  // For now, channel is a string, but we may switch it back
  // as we learn more.
  // channel:
  //   | "brokerage"
  //   | "dtc"
  //   | "indy sales center"
  //   | "affiliates"
  //   | "ecommerce"
  //   | "call center";
  channel: string | object | null | undefined;
  agency_id: string | null | undefined;
  agent_id: string | null | undefined;
}

/** Gets information about the Pivot Health marketing/sales channel for Snowplow. */
export interface PhChannelDataProvider {
  /** Read Pivot Health channel data from a data source. */
  readPhChannelContextData: (contextEvent?: ContextEvent) => PhChannelData;
}

/** Gets information about the Pivot Health sales channel from `localStorage` for Snowplow. */
export const localStoragePhChannelProvider: PhChannelDataProvider = {
  readPhChannelContextData: (): PhChannelData => {
    try {
      const hcData = getHcStorage();

      return {
        channel:
          typeof hcData?.channel === "string"
            ? hcData.channel.toLocaleLowerCase()
            : undefined,
        agency_id: hcData?.agency_id,
        agent_id: hcData?.agent_id,
      };
    } catch (error) {
      console.error(error);
      return { channel: null, agency_id: null, agent_id: null };
    }
  },
};
