import MuiCheckIcon from "@mui/icons-material/Check";
import React from "react";

/**
 * Props for the CheckboxIcon Builder component
 * @param color - The color that the checkbox will be displayed in
 */
export interface CheckIconProps {
  color: string;
}

/** Returns a green checkbox icon given an hex color */
export default function CheckIcon(props: CheckIconProps) {
  return (
    <MuiCheckIcon
      sx={{
        color: props.color,
        fontWeight: "800",
        stroke: props.color,
        strokeWidth: "1",
      }}
    />
  );
}
