import type { SelfDescribingJson } from "@snowplow/browser-tracker";
import type { PhChannelDataProvider } from "./PhChannelDataProvider";

/**
 * Identifier of the schema for the `ph_channel` context.
 *
 * @see {@link https://github.com/healthcarecom/Data-Platform/tree/main/snowplow/schemas/com.healthcare/ph_channel/jsonschema}
 */
export const PH_IGLU_SCHEMA = "iglu:com.healthcare/ph_channel/jsonschema/1-0-1";

/**
 * Returns Pivot Health channel data in a format that Snowplow can use.
 * @param dataProvider - Returns Pivot Health sales channel data from a data source
 * @returns A function that uses the `dataProvider` to generate Pivot Health sales channel data
 * for use in Snowplow.
 */
export default function phChannelContextGenerator(
  dataProvider: PhChannelDataProvider
): () => SelfDescribingJson {
  return () => {
    const data = dataProvider.readPhChannelContextData();
    return {
      schema: PH_IGLU_SCHEMA,
      data: {
        channel: data.channel,
        agency_id: data.agency_id,
        agent_id: data.agent_id,
      },
    };
  };
}
