import Script from "next/script";

/** Props for the ImpactRadius tracker */
export interface ImpactRadiusProps {
  /** The URL for the Impact Radius script */
  url: string;
}

/**
 * Loads the Impact Radius tracker
 * @param props - See ImpactRadiusProps
 * @returns The script tag for the Impact Radius tracker
 */
export default function ImpactRadius(props: ImpactRadiusProps) {
  const enabled = process.env.NEXT_PUBLIC_ENABLE_THIRD_PARTY_SCRIPTS === "true";

  if (!enabled) return <></>;

  return (
    <Script id="impact-radius-tracker">
      {`
      (function(a, b, c, d, e, f, g) {
        e['ire_o'] = c;
        e[c] = e[c] || function() {
            (e[c].a = e[c].a || []).push(arguments)
        };
        f = d.createElement(b);
        g = d.getElementsByTagName(b)[0];
        f.async = 1;
        f.src = a;
        g.parentNode.insertBefore(f, g);
      })('${props.url}', 'script', 'ire', document, window);
      `}
    </Script>
  );
}
