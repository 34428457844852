import { BuildEventProps, Placement } from "./snowplow-events.types";

const CLICK_ACTION = "click";

const buildEvent = ({
  category,
  action = CLICK_ACTION,
  placement = Placement.bottom,
  label,
  value,
  elementId,
  targetUrl,
  description,
}: BuildEventProps) => {
  return {
    category,
    placement,
    action,
    label,
    value,
    elementId,
    targetUrl,
    description,
  };
};

/**
 * Generates a click event from a Builder.io ButtonBox button click
 * @param value - The text content of the ButtonBox the user interacted with
 * @param targetUrl - The URL that the user was redirected to after clicking
 * @returns A Snowplow event with the props attached
 */
export const buttonBoxClickEvent = (options: {
  value: string;
  targetUrl: string;
}) =>
  buildEvent({
    category: "landing",
    value: options.value,
    label: `Button Box`,
    targetUrl: options.targetUrl,
    elementId: "button_box",
    description:
      "Create an event when user clicks on a Button Box button from the landing page",
  });

/** Generates an event to be used when the user clicks the Invoca widget and opens the modal */
export const invocaModalOpenEvent = buildEvent({
  label: "Invoca Modal",
  category: "header",
  value: "Open",
  elementId: "invoca_widget",
});

/** Generates an event to be used when the user closes the Invoca widget's modal */
export const invocaModalCloseEvent = buildEvent({
  label: "Invoca Modal",
  category: "header",
  value: "Close",
  elementId: "invoca_widget",
});

/**
 * Generates an event to be used when the user enters a phone number and clicks submit
 * @param phoneNumber - The phone number submitted by the user
 * @returns A Snowplow event with the user's phone number attached
 */
export const phoneNumberSubmitEvent = (phoneNumber: string) =>
  buildEvent({
    label: "Phone Number Submit",
    category: "header",
    value: phoneNumber,
    elementId: "phone_number_field",
  });

/** Generates an event to be used when the user clicks the View Previous Quote button */
export const viewPreviousQuoteEvent = buildEvent({
  label: "Previous Quote Banner",
  category: "landing",
  value: "View Quote",
  elementId: "previous_quote_banner",
});

/** Generates an event to be used when the user clicks to close the Previous Quote Banner */
export const closePreviousQuoteBannerEvent = buildEvent({
  label: "Previous Quote Banner",
  category: "landing",
  value: "Close",
  elementId: "previous_quote_banner",
});

/**
 * Generates an event to be used when the user clicks to open an Expando
 * @param title - The title of the Expando that was opened
 * @returns A Snowplow event with the title attached
 */
export const openExpandoEvent = (title: string) =>
  buildEvent({
    label: "Expando",
    category: "landing",
    value: `Open: ${title}`,
    elementId: "expando",
  });

/**
 * Generates an event to be used when the user clicks to close an Expando
 * @param title - The title of the Expando that was closed
 * @returns A Snowplow event with the title attached
 */
export const closeExpandoEvent = (title: string) =>
  buildEvent({
    label: "Expando",
    category: "landing",
    value: `Close: ${title}`,
    elementId: "expando",
  });

/**
 * Generates an event to be used when the user clicks to submit their zip code
 * @param zipCode - The entered zip code
 * @returns A Snowplow event with the zip code attached
 */
export const zipCodeSubmitEvent = (zipCode: string) =>
  buildEvent({
    label: "Zip Code Submit",
    category: "landing",
    value: zipCode,
    elementId: "zip_code_submit",
  });
