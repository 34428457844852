// Based on types in @hc-frontend/entities

/** Height of a person expressed in feet and inches. */
export type Height = {
  feet: number;
  inches: number;
};

/** Member type for an app. */
export enum MemberType {
  Applicant = "primary",
  Spouse = "spouse",
  Child = "child",
}

/** The gender identifier of a Member. */
export enum Gender {
  Male = "m",
  Female = "f",
}

/** The tobacco use identifier of a Member. */
export enum TobaccoUse {
  Yes = "y",
  No = "n",
}

/**
 * The member is a collection of data that is needed for a person to
 * apply for insurance. This is the base for all kind of members.
 */
export interface Member {
  id: string;
  type: MemberType;
  gender: Gender;
  dob: Date;
  age: number;
  stringDOB: string;
  tobacco?: TobaccoUse;
  firstName?: string;
  lastName?: string;
  weight?: number;
  height?: Height;
}

/** Represents the member to whom the insurance policy has been assigned. */
export interface Applicant extends Member {
  type: MemberType.Applicant;
}

/** Represents the spouse of the Applicant. */
export interface Spouse extends Member {
  type: MemberType.Spouse;
}

/** Represents the children of the Applicant. */
export interface Child extends Member {
  type: MemberType.Child;
}

/** Represents all members that are not the Applicant. */
export interface Dependent extends Member {
  type: MemberType.Child | MemberType.Spouse;
}
