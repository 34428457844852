import { gql } from "../generated/client";

export const LEAD_AUDIT = gql(/* GraphQL */ `
  mutation LeadAudit($payload: LeadAuditPayload!) {
    leadAudit(payload: $payload) {
      data
      error
      status
    }
  }
`);
