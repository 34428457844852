import { Box, Link, Typography } from "@mui/material";
import React from "react";

/**
 * Renders a warning to the user if they are viewing a page in a non-production environment
 */
export function EnvironmentWarning() {
  const env = process.env["NEXT_PUBLIC_ENV"];
  const enabled = process.env["NEXT_PUBLIC_ENV_WARNING_ENABLED"] === "true";

  // If the environment is production, render nothing
  if (!enabled || env === "production") return <></>;

  // If the environment is anything other than production, render the warning
  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: "#293e53",
        zIndex: 5,
        position: "relative",
      }}
      color="#fff"
      fontFamily="proxima-nova, sans-serif"
    >
      <Typography
        textAlign="center"
        fontFamily="proxima-nova, sans-serif"
        fontWeight={600}
        fontSize="18px"
        lineHeight={1.45}
      >
        You are viewing an INACTIVE website page.
      </Typography>
      <Typography
        textAlign="center"
        fontFamily="proxima-nova, sans-serif"
        fontWeight={600}
        fontSize="18px"
        lineHeight={1.45}
      >
        Please&nbsp;
        <Link
          href="https://enroll.pivothealth.com"
          sx={{
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#697887",
            fontWeight: 800,
            "&:hover": {
              color: "#5f89c3",
              textDecoration: "underline",
            },
          }}
        >
          click here
        </Link>
        &nbsp;and you will be redirected to the ACTIVE Pivot Health website.
      </Typography>
    </Box>
  );
}

export default EnvironmentWarning;
