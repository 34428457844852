import Script from "next/script";

/** Initialize Blueshift API script and trigger a page load event. */
export default function Blueshift() {
  const enabled = process.env.NEXT_PUBLIC_ENABLE_THIRD_PARTY_SCRIPTS === "true";
  const apiKey = process.env.NEXT_PUBLIC_BLUESHIFT_EVENT_API_KEY;

  if (!enabled) return <></>;

  /* cspell:disable */
  return (
    <Script
      type="text/javascript"
      id="blueshift-load"
      data-testid="blueshift-load"
    >{`
      window._blueshiftid='${apiKey}';window.blueshift=window.blueshift||[];if(blueshift.constructor===Array){blueshift.load=function(){var d=function(a){return function(){blueshift.push([a].concat(Array.prototype.slice.call(arguments,0)))}},e=["identify","track","click","pageload","capture","retarget"];for(var f=0;f<e.length;f++)blueshift[e[f]]=d(e[f])};}
      blueshift.load();
      blueshift.pageload();
      if(blueshift.constructor===Array){(function(){var b=document.createElement("script");b.type="text/javascript",b.async=!0,b.src=("https:"===document.location.protocol?"https:":"http:")+"//cdn.getblueshift.com/blueshift.js";var c=document.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c);})()}
    `}</Script>
  );
  /* cspell:enable */
}
