import { Box, Button, Fade, Typography, useTheme } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import React from "react";

/** A button to scroll the user to the top of the document */
export default function ScrollToTop() {
  const [showButton, setShowButton] = React.useState(false);
  const theme = useTheme();

  React.useEffect(() => {
    // Define function to determine whether scroll to top should be visible
    const handleScrollButtonVisibility = () => {
      window.scrollY > 300 ? setShowButton(true) : setShowButton(false);
    };

    // Add event listeners to call the above functions on scroll and window resize
    window.addEventListener("scroll", handleScrollButtonVisibility);

    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisibility);
    };
  }, []);
  return (
    <Fade in={showButton} unmountOnExit>
      <Box>
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "999px",
              color: "#fff",
              height: "60px",
              textAlign: "center",
              backgroundColor: theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <ArrowUpwardIcon
              sx={{
                fontSize: "2rem",
              }}
            />
          </Button>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "0px",
              color: "#fff",
              paddingLeft: "8px",
              textAlign: "center",
              backgroundColor: theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <ArrowUpwardIcon
              sx={{
                marginLeft: "0px",
              }}
            />
            <Typography
              textTransform="uppercase"
              fontFamily="proxima-nova, sans-serif"
              fontSize=".875rem"
              fontWeight="800"
              marginLeft="5px"
            >
              Back To Top
            </Typography>
          </Button>
        </Box>
      </Box>
    </Fade>
  );
}
