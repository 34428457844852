import Script from "next/script";

/** Props for the UserWay widget */
export interface UserWayProps {
  /** The URL from which the UserWay script will be loaded */
  url: string;
}

/**
 * Loads the UserWay accessibility widget via script
 * @param props - See UserWayProps
 * @returns A script to load and render the UserWay accessibility widget
 */
export default function UserWay(props: UserWayProps) {
  const enabled = process.env.NEXT_PUBLIC_ENABLE_THIRD_PARTY_SCRIPTS === "true";

  if (!enabled) return <></>;

  return <Script src={props.url} />;
}
