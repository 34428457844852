import { Box, Collapse, Link, Typography } from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export interface MobileHomepageAccordionProps {
  title: string;
  content?: string;
  learnMoreLink?: string;
  color: string;
  image?: string;
  setOpen: (title: string, open: boolean) => void;
  open: boolean;
}

export default function MobileHomepageAccordion(
  props: MobileHomepageAccordionProps
) {
  return (
    <Box
      sx={{
        backgroundColor: props.color,
        borderRadius: "20px",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "12px",
        marginBottom: "10px",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "70px 68% auto",
          alignItems: "center",
        }}
        onClick={() => {
          props.setOpen(props.title, !props.open);
        }}
      >
        <Box
          sx={{
            height: "60px",
            width: "60px",
            backgroundColor: "rgba(255,255,255,0.6)",
            borderRadius: "9999px",
            display: "block",
          }}
        >
          <Box
            component="img"
            src={props.image}
            sx={{
              margin: "auto",
              width: "40px",
              height: "50px",
              marginTop: "5px",
              objectFit: "contain",
              display: "block",
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            fontFamily: "Poppins, sans-serif",
            fontSize: "20px",
            fontWeight: 400,
          }}
        >
          {props.title}
        </Box>
        <Box
          sx={{
            margin: "auto",
            textAlign: "center",
          }}
        >
          {props.open ? (
            <RemoveCircleOutlineIcon
              sx={{
                display: "block",
                margin: "auto",
                color: "#fff",
                fontSize: "32px",
                fontWeight: 300,
                textAlign: "center",
              }}
            />
          ) : (
            <AddCircleOutlineIcon
              sx={{
                display: "block",
                margin: "auto",
                color: "#fff",
                fontSize: "32px",
                fontWeight: 300,
                textAlign: "center",
              }}
            />
          )}
        </Box>
      </Box>
      <Collapse in={props.open}>
        <Box
          sx={{
            paddingTop: "10px",
            paddingBottom: "40px",
            fontFamily: "DM Sans, sans-serif",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          <Typography
            sx={{
              marginBottom: props.learnMoreLink ? "50px" : "0px",
            }}
          >
            {props.content}
          </Typography>
          <Box>
            {props.learnMoreLink && (
              <Link
                href={props.learnMoreLink}
                sx={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "#000",
                  paddingX: "25px",
                  paddingY: "15px",
                  border: "1px solid black",
                  borderRadius: "9999px",
                }}
              >
                Learn More
              </Link>
            )}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}
