import { gql } from "../generated/client";

/** Query to get location data from a user's IP address */
export const GET_LOCATION_FROM_USER_IP = gql(/* GraphQL */ `
  query GetLocationFromUserIp {
    getLocationFromUserIp {
      ip
      country
      countryName
      stateId
      stateName
      stateAbbreviation
      city
      zip
      latitude
      longitude
      countyId
      countyName
      fips
      ssa
      displayName
    }
  }
`);
