import { gql } from "../generated/client";

export const CREATE_SESSION = gql(/* GraphQL */ `
  mutation CreateSession($payload: CreateSessionInput!) {
    createSession(payload: $payload) {
      sessionId
      UserId
    }
  }
`);
