import { Box } from "@mui/material";
import { BuilderDefaultComponentProps } from "./BuilderH1";
import React from "react";
export default function BuilderText(props: BuilderDefaultComponentProps) {
  return (
    <Box
      sx={{
        "& p, h1, h2, h3, h4, h5, h6": {
          all: "unset",
        },
      }}
      component="div"
      dangerouslySetInnerHTML={{ __html: props.text }}
    />
  );
}
