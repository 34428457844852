import { gql } from "../generated/client";

/** Query to get location data given an IP address. */
export const GET_INVOCA_CAMPAIGN = gql(/* GraphQL */ `
  query GetInvocaCampaign($payload: CallCampaignPayload!) {
    getCallCampaign(payload: $payload) {
      data {
        placement
        logo
        zip
        impression_id
        advertiser {
          name
          logo
        }
        phone {
          promo_number_formatted
          promo_number
          tracking_url
          link_invoca
          tty_formatted
        }
        schedule {
          sunday {
            from
            to
          }
          saturday {
            from
            to
          }
          tuesday {
            from
            to
          }
          Thursday {
            from
            to
          }
          wednesday {
            from
            to
          }
          friday {
            from
            to
          }
          monday {
            from
            to
          }
        }
      }
    }
  }
`);
