import React from "react";
import { useAppSelector } from "../../state/hooks";

export function useGTMUserId() {
  const trackingEnabled =
    process.env["NEXT_PUBLIC_ENABLE_THIRD_PARTY_SCRIPTS"] === "true";
  const userId = useAppSelector((state) => state.hc.healthcareUserId);
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const win = window as any;
      if (trackingEnabled && userId && win?.dataLayer) {
        win.dataLayer.push({
          user_id: userId,
        });
      }
    }
  }, [userId, trackingEnabled]);
}

export default useGTMUserId;
