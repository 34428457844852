import {
  anonymizedPiiContextProvider,
  hcSessionContextProvider,
  initSnowplowTracker,
  localStorageAnonymizedPiiDataProvider,
  localStorageHcSessionDataProvider,
  refreshLinkTracking,
} from "@hc-frontend/third-party-snowplow";
import { FormTrackingPlugin } from "@snowplow/browser-plugin-form-tracking";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { updateDomainUserId } from "../../state/features/user/userSlice";
import { useAppDispatch } from "../../state/hooks";
import { localStoragePhChannelProvider } from "../PhChannelDataProvider";
import phChannelContextProvider from "../phChannelContextProvider";

/**
 * Hook to initialize Snowplow tracking on a page and fire a `pageView` event.
 * Once the Snowplow tracker initializes, the user ID is updated in the Redux store
 * for easy access elsewhere.
 */
export function usePageView() {
  const enabled = process.env["NEXT_PUBLIC_ENABLE_SNOWPLOW"] === "true";
  const router = useRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (enabled) {
      const { pageView, tracker } = initSnowplowTracker({
        isProd: process.env["NEXT_PUBLIC_ENV"] === "production",
        plugins: [FormTrackingPlugin()],
        contexts: [
          hcSessionContextProvider(localStorageHcSessionDataProvider),
          anonymizedPiiContextProvider(localStorageAnonymizedPiiDataProvider),
          phChannelContextProvider(localStoragePhChannelProvider),
        ],
      });

      if (tracker) {
        dispatch(updateDomainUserId(tracker.getDomainUserId()));
      }

      pageView();

      router.events.on("routeChangeStart", pageView);
      router.events.on("routeChangeComplete", () => refreshLinkTracking({}));
    }
  }, [router.events, enabled, dispatch]);
}

export default usePageView;
