import { useLazyQuery, useQuery } from "@apollo/client";
import { trackUserClick } from "@hc-frontend/third-party-snowplow";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MuiCheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { documents } from "dtc-graphql-schema";
import React from "react";
import { zipCodeSubmitEvent } from "../../events/snowplow-events";
import validateZipCode from "../../helpers/zipCode";
import { dtcTheme } from "../../theme/theme";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import handleZipChange from "./handleZipChange";

/**
 * Renders a minimal Zip Code input widget and stores location info from the user, either through GeoIP or given their ZIP code
 */
export default function ThinZipCodeWidget() {
  const [zipCode, setZipCode] = React.useState("");
  const [userHasInteracted, setUserHasInteracted] = React.useState(false);

  const zipFromStorage = useAppSelector((state) => state.hc.location.zip_code);
  const dispatch = useAppDispatch();

  const { error: locationError, data: locationData } = useQuery(
    documents.GET_LOCATION_FROM_USER_IP
  );
  const [locationFromZip] = useLazyQuery(documents.GET_LOCATION_FROM_ZIP);

  React.useEffect(() => {
    // If there is a stored value for the zip in the session, default to that
    setZipCode(zipFromStorage);
  }, [zipFromStorage]);

  const [showZipError, setShowZipError] = React.useState(false);

  React.useEffect(() => {
    if (!locationData) return;
    if (
      !locationError &&
      locationData.getLocationFromUserIp.length > 0 &&
      zipCode === "" &&
      !userHasInteracted
    ) {
      // If the user has not entered a zip and we're defaulting to IP geolocation, store their zip on session
      const location = locationData.getLocationFromUserIp[0];
      if (location.zip && location.zip.length >= 5) {
        setZipCode(location.zip);
        handleZipChange({
          locations: locationData.getLocationFromUserIp.map((location) => ({
            stateName: location.stateName,
            stateAbbreviation: location.stateAbbreviation,
            zip: location.zip,
            city: location.city,
            countyName: location.countyName,
            stateId: location.stateId,
          })),
          setShowZipError: setShowZipError,
          dispatch: dispatch,
        });
      }
    }
  }, [locationData, locationError, zipCode, dispatch, userHasInteracted]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setUserHasInteracted(true);
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setZipCode(e.target.value);
      setShowZipError(false);
    }

    // When the user's zip code changes and is 5 characters long, use geolocation on it and store the result in storage
    async function loadLocation() {
      const locationResponse = await locationFromZip({
        variables: {
          zip: e.target.value,
        },
      });

      handleZipChange({
        locations: locationResponse.data
          ? locationResponse.data?.getLocationFromZip.map((location) => ({
              stateName: location.stateName,
              stateAbbreviation: location.stateAbbreviation,
              zip: location.zip,
              city: location.city,
              countyName: location.countyName,
            }))
          : /* c8 ignore next */
            [],
        setShowZipError: setShowZipError,
        dispatch: dispatch,
      });
    }

    if (e.target.value.length === 5) {
      loadLocation();
    }
  };

  return (
    <Box>
      <Grid
        border="1px solid #fff"
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={6}>
          <TextField
            variant="filled"
            fullWidth
            placeholder="Enter Zip"
            type="text"
            onChange={handleChange}
            value={zipCode}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  {zipCode.length === 5 && (
                    <MuiCheckIcon
                      sx={{
                        color: dtcTheme.palette.success.main,
                        fontWeight: "800",
                        stroke: dtcTheme.palette.success.main,
                        strokeWidth: "1",
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            inputProps={{
              inputMode: "decimal",
              maxLength: 5,
              style: {
                fontSize: "26px",
                paddingTop: "8px",
                fontFamily: "proxima-nova, sans-serif",
                fontWeight: 600,
                color: "#a9a9a9",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              borderRadius: "0px",
            }}
            onClick={() => {
              if (validateZipCode(zipCode) && !showZipError) {
                trackUserClick(zipCodeSubmitEvent(zipCode));
                window.open(`/census/?zip=${zipCode}`, "__blank");
                window.location.assign(`/fas/?zip=${zipCode}`);
              } else {
                setShowZipError(true);
              }
            }}
          >
            See Our Plans
            <ArrowForwardIosIcon sx={{ marginLeft: "20px" }} />
          </Button>
        </Grid>
      </Grid>
      <Box height="30px">
        <Typography
          display={showZipError ? "block" : "none"}
          fontFamily="proxima-nova, sans-serif"
          fontWeight={400}
          fontSize="12px"
          sx={{
            color: "#ffaca6",
          }}
        >
          Please enter your 5-digit ZIP code.
        </Typography>
      </Box>
    </Box>
  );
}
