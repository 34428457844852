import { gql } from "../generated/client";

/** Query to get location data given an IP address. */
export const GET_LOCATION_FROM_IP = gql(/* GraphQL */ `
  query GetLocationFromIp($ip: String!) {
    getLocationFromIp(ip: $ip) {
      ip
      country
      countryName
      stateId
      stateName
      stateAbbreviation
      city
      zip
      latitude
      longitude
      countyId
      countyName
      fips
      ssa
      displayName
    }
  }
`);
