import { Typography } from "@mui/material";
import { BuilderDefaultComponentProps } from "./BuilderH1";
import React from "react";

export default function BuilderH6(props: BuilderDefaultComponentProps) {
  return (
    <Typography
      component="h6"
      sx={{
        all: "unset",
      }}
    >
      {props.text}
    </Typography>
  );
}
