import { Grid, Typography } from "@mui/material";
import ButtonBox, { ButtonBoxProps } from "./ButtonBox";
import React from "react";

/** Props for the ButtonBoxContainer component */
export interface ButtonBoxContainerProps {
  /** An array of properties for rendering ButtonBox components - see ButtonBoxProps */
  boxConfigurations: ButtonBoxProps[];
}

/**
 * Renders a collection of ButtonBox widgets in a responsive grid
 */
export default function ButtonBoxContainer(props: ButtonBoxContainerProps) {
  // Early return to render an error if no boxes are defined
  if (!props.boxConfigurations || props.boxConfigurations.length === 0)
    return <Typography>Add box configurations through Builder.io</Typography>;

  // Iterate over each item to add a key given their position in the array
  const indexedBoxes = props.boxConfigurations.map((box, index) => {
    return {
      boxProps: box,
      id: index,
    };
  });

  return (
    <Grid
      container
      columns={{
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      }}
      columnSpacing={{
        xs: 2,
        sm: 3,
        md: 3,
        lg: 3,
      }}
      rowSpacing={2}
      justifyContent="space-evenly"
    >
      {indexedBoxes.map((box) => {
        return (
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={4}
            key={box.id}
            justifyContent="center"
          >
            <ButtonBox {...box.boxProps} />
          </Grid>
        );
      })}
    </Grid>
  );
}
