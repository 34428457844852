/* c8 ignore start */
import { ConditionalContextProvider } from "@snowplow/browser-tracker";
import { PhChannelDataProvider } from "./PhChannelDataProvider";
import phChannelContextFilter from "./phChannelContextFilter";
import phChannelContextGenerator from "./phChannelContextGenerator";

/**
 * Provides a conditional Snowplow context that can add data to Snowplow events
 * if certain conditions are met.
 * @param dataProvider - Provides Pivot Health channel data from some data source
 * @returns A Snowplow context provider that can add data to Snowplow events if
 * certain conditions are met
 */
export default function phChannelContextProvider(
  dataProvider: PhChannelDataProvider
): ConditionalContextProvider {
  return [
    phChannelContextFilter(dataProvider),
    phChannelContextGenerator(dataProvider),
  ];
}

/* c8 ignore stop */
