/** Represents the placement of an event source on the page */
export enum Placement {
  bottom = "form-bottom",
  modal = "modal",
}

/** Represents values that will be transmitted with a Snowplow Event */
export interface BuildEventProps {
  /** The category of the event */
  category: string;
  /** The placement of the event's source */
  placement?: Placement;
  /** The type of action that is being triggered */
  action?: string;
  /** A label describing the event */
  label: string;
  /** The value attached to the event */
  value: string;
  /** An identifier for the event */
  elementId: string;
  /** The URL that the user is directed to due to the event */
  targetUrl?: string;
  /** A human-readable description of the event being created */
  description?: string;
}
