import React from "react";
import Schedule, { HourRange } from "../state/features/hc/Schedule";
import { useAppSelector } from "../state/hooks";

/**
 * Determines based on the sales center availability in application state whether the sales center is open
 * @returns A boolean value, true if available, false if unavailable
 */
export default function useSalesCenterAvailable(): boolean {
  const [available, setAvailable] = React.useState(false);
  const schedule = useAppSelector(
    (state) => state.hc.sales_center_availability
  );

  React.useEffect(() => {
    // Convert the user's local date to a date in US Eastern Time
    const withTz = new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
    });
    const tzDate = new Date(withTz);

    // Pull the schedule for the time-zoned date
    const todaysAvailability: HourRange = getTodaysSchedule(schedule, tzDate);

    // Get the current hour of the time-zoned date
    const currentHour = tzDate.getHours();

    // If the schedule for the day is available, set availability based on whether the current hour is within the bounds
    if (todaysAvailability.from && todaysAvailability.to) {
      setAvailable(
        currentHour >= parseInt(todaysAvailability.from) &&
          currentHour < parseInt(todaysAvailability.to)
      );
    } else setAvailable(false);
  }, [schedule]);

  return available;
}

function getTodaysSchedule(schedule: Schedule, today: Date) {
  /* c8 ignore next 15 */
  switch (today.getDay()) {
    case 0:
      return schedule.sunday;
    case 1:
      return schedule.monday;
    case 2:
      return schedule.tuesday;
    case 3:
      return schedule.wednesday;
    case 4:
      return schedule.thursday;
    case 5:
      return schedule.friday;
    default:
      return schedule.saturday;
  }
}
