import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { AppProps } from "next/app";
import Head from "next/head";
import { Provider as ReduxProvider } from "react-redux";
import { initializeStore } from "@ph/builder";
import ImpactRadius from "../trackers/ImpactRadius";
import Jornaya from "../trackers/Jornaya";
import MicrosoftBing from "../trackers/MicrosoftBing";
import OptinMonster from "../trackers/OptinMonster";
import UserWay from "../trackers/UserWay";
import Blueshift from "../trackers/Blueshift";

const client = new ApolloClient({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
  cache: new InMemoryCache(),
});

const store = initializeStore();

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Flexible, Budget-Friendly Health Insurance Plans</title>
        <title>Welcome to dtc!</title>
        <meta charSet="UTF-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="description"
          content="Pivot Health temporary & supplemental health insurance. Learn more about our health insurance alternatives."
        />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />
        <meta
          name="keywords"
          content="Short Term Medical, STM, Pivot Health, Temporary Health Insurance, Insurance"
        />
        <meta
          httpEquiv="delegate-ch"
          content="sec-ch-ua-full-version-list https://sp.pivothealth.com;"
        />
        <meta
          httpEquiv="Accept-CH"
          content="Viewport-Width,Width,DPR,Device-Memory,Downlink,ECT,RTT"
        />
        <meta name="SKYPE_TOOLBAR" content="SKYPE_TOOLBAR_PARSER_COMPATIBLE" />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <meta name="format-detection" content="telephone=no" />
      </Head>
      <main className="app">
        <ReduxProvider store={store}>
          <ApolloProvider client={client}>
            <Component {...pageProps} />

            {/** Trackers ↓ */}
            <OptinMonster />
            <UserWay url={process.env.NEXT_PUBLIC_USERWAY_URL || ""} />
            <ImpactRadius
              url={process.env.NEXT_PUBLIC_IMPACT_RADIUS_URL || ""}
            />
            <MicrosoftBing url={process.env.NEXT_PUBLIC_BING_URL || ""} />
            <Jornaya url={process.env.NEXT_PUBLIC_JORNAYA_URL || ""} />
            <Blueshift />
          </ApolloProvider>
        </ReduxProvider>
      </main>
    </>
  );
}
