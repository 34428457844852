import { Box } from "@mui/material";
import React from "react";

/** Renders the hero image SVG for the DTC homepage
 *  This component is necessary due to its explicit setting of the z-index to 1
 *  When an SVG image is added via Builder.io's image component and its z-index is set via Builder, it always renders higher
 *  than any other components, regardless of what value is set in Builder.
 *  This created issues hiding the hero image behind the Zip Code widget's backdrop.
 */
export default function HeroImage() {
  if (!process.env["NEXT_PUBLIC_HERO_IMAGE_URL"]) {
    throw new TypeError(
      "Missing environment variable NEXT_PUBLIC_HERO_IMAGE_URL"
    );
  }

  return (
    <Box
      sx={{ zIndex: 1 }}
      component="img"
      src={process.env["NEXT_PUBLIC_HERO_IMAGE_URL"]}
    />
  );
}
