import Script from "next/script";
import React from "react";
import { useAppDispatch, useAppSelector } from "@ph/builder";
import { update } from "@ph/builder";

/** Props for the Jornaya tracker */
export interface JornayaProps {
  /** The URL of the Jornaya script */
  url: string;
}

/**
 * Loads the Jornaya site-wide tracking script
 * @param props - See JornayaProps
 * @returns The Jornaya site-wide tracking script
 */
export default function Jornaya(props: JornayaProps) {
  const enabled = process.env.NEXT_PUBLIC_ENABLE_THIRD_PARTY_SCRIPTS === "true";
  const hcStorage = useAppSelector((state) => state.hc);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    // Create an interval to check for when the window.LeadiD is populated
    const leadIdCheckInterval = setInterval(() => {
      if (window.LeadiD) {
        // Once the object is populated, clear the interval so it stops polling
        clearInterval(leadIdCheckInterval);
        // Push the tcpa_universal_leadid to storage
        dispatch(
          update({
            ...hcStorage,
            leadid: {
              universal_leadid: window.LeadiD.token,
              sended: false,
            },
          })
        );
      }
    }, 1000);
  }, []); // eslint-disable-line

  if (!enabled) return <></>;

  return (
    <Script id="LeadiDscript" data-testid="leadidscript">
      {`
        (function() {
          const s = document.createElement('script');
          s.id = 'LeadiDscript_campaign';
          s.type = 'text/javascript';
          s.async = true;
          s.src = '${props.url}';
          const LeadiDscript = document.getElementById('LeadiDscript');
          LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
          })();
        `}
    </Script>
  );
}
