/** Represents a Category to which posts can belong */
export interface WordPressCategory {
  /** The ID of the category */
  id: number;
  /** The number of posts that belong to the category */
  count: number;
  /** The description of the category */
  description: string;
  /** A link to the category */
  link: string;
  /** The human-readable name of the category */
  name: string;
  /** The slug associated with the category */
  slug: string;
}

/**
 * Pulls all the possible blog post categories from the WordPress API
 * @param categoriesPerPage - The number of categories to pull per request - cannot exceed 100
 * @returns An array of WordPressCategory objects (see above)
 */
export async function GetCategories(
  categoriesPerPage: number = 100,
  wordpressDomain: string
): Promise<WordPressCategory[]> {
  if (categoriesPerPage > 100 || categoriesPerPage < 1) {
    throw new TypeError(
      "The WordPress API supports category page sizes of 1 to 100 inclusive.  If unsure, use 100."
    );
  }

  const urlRoot = wordpressDomain;
  if (!urlRoot) throw new TypeError("WordPress domain is required");

  // Create an array to hold all the categories
  let allCategories: WordPressCategory[] = [];

  async function fetchCategories(page: number) {
    // Get categories for the given page number
    const response = await fetch(
      `${urlRoot}/categories?per_page=${categoriesPerPage}&page=${page}`
    );
    if (!response.ok) {
      throw new Error("There was an error fetching WordPress categories");
    }
    const categories = (await response.json()) as WordPressCategory[];
    // Add the fetched categories to the array of all categories
    allCategories = allCategories.concat(categories);
    // If the array of categories is equal to the max page size, it means there are likely more categories
    // In this case, fetch the next page of categories
    // If the array of returned categories is not the page size, it means we've reached the last page
    if (categories.length === categoriesPerPage) {
      await fetchCategories(page + 1);
    }
  }

  await fetchCategories(1);
  return allCategories;
}
