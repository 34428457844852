import type { Variants } from "@amplitude/experiment-js-client";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

/** Information about the current user */
export interface UserState {
  /** User ID assigned by Snowplow */
  domainUserId?: string;
  /** Amplitude variant data */
  variants?: Variants;
  /** Whether the user's variants have been loaded by the Amplitude SDK yet. */
  variantsLoaded: boolean;
}

const initialState: UserState = {
  variantsLoaded: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    updateUser: (state: UserState, action: PayloadAction<UserState>) => ({
      ...state,
      ...action.payload,
    }),
    updateDomainUserId: (state: UserState, action: PayloadAction<string>) => ({
      ...state,
      domainUserId: action.payload,
    }),
  },
});

export const { updateUser, updateDomainUserId } = userSlice.actions;

export default userSlice.reducer;
