import { AccordionDetails, AccordionSummary, Box } from "@mui/material";
import React from "react";
import { Accordion as MuiAccordion } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";

/** Props for the FAQAccordion and OutlinedAccordion */
export interface AccordionProps {
  /** Title/Content pairs - title is a string, content is rich-text and treated as html */
  accordionItems: {
    title: string;
    content: string;
  }[];
  /** The font size of the title */
  titleFontSize: string;
  /** The font weight of the title */
  titleFontWeight: number;
  /** The color of the title */
  titleColor: string;
  /** The font size of the content (color and weight are configurable in Builder) */
  contentFontSize: string;
}

/**
 * Represents a full-width accordion for displaying FAQs
 * Examples of placements of this component can be seen on:
 *   The enroll landing page (bottom)
 *   The agents landing page (bottom)
 * @param props - See AccordionProps
 * @returns An accordion component for displaying FAQs and their answers
 */
export default function FAQAccordion(props: AccordionProps) {
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
        paddingLeft: "10px",
        paddingRight: "20px",
      }}
    >
      {props.accordionItems.map((accordionItem, index) => {
        return (
          <MuiAccordion
            disableGutters
            key={index}
            expanded={openIndex === index}
            data-testid={
              openIndex === index
                ? `collapse-${index}-open`
                : `collapse-${index}-closed`
            }
            onChange={() => {
              if (openIndex === index) setOpenIndex(null);
              else setOpenIndex(index);
            }}
            sx={{
              backgroundColor: "rgba(0,0,0,0)",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              sx={{
                fontSize: props.titleFontSize,
                fontWeight: props.titleFontWeight,
                color: props.titleColor,
                "&.Mui-expanded": {
                  minHeight: 0,
                  borderTop:
                    index !== 0 ? "1px solid rgba(48, 58, 82, 0.29)" : "none",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: "12px 0",
                },
              }}
              expandIcon={<KeyboardArrowUp sx={{ fontSize: "40px" }} />}
              onClick={() => {
                if (openIndex === index) setOpenIndex(null);
                else setOpenIndex(index);
              }}
            >
              {accordionItem.title}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                fontSize: props.contentFontSize,
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              <Box
                component="div"
                dangerouslySetInnerHTML={{ __html: accordionItem.content }}
              ></Box>
            </AccordionDetails>
          </MuiAccordion>
        );
      })}
    </Box>
  );
}
