import { Typography } from "@mui/material";
import React from "react";
import Typewriter from "typewriter-effect";

/**
 * Props for the TypewriterText widget
 * @param fontFamily - The font family that the text will use
 * @param fontWeight - The weight of the font
 * @param fontColor - The color of the font as a string - can be hex, rgb(a), or a default color like "black"
 * @param typewriterStrings - A list of objects containing a string parameter than will be animated through by the widget
 */
export interface TypewriterTextProps {
  fontFamily: string;
  fontWeight: number;
  fontColor: string;
  fontSize: string;
  typewriterStrings: {
    textString: string;
  }[];
}

/**
 * A widget to animate the typing out and backspacing of a list of strings
 * @param props - See TypewriterTextProps
 */
export default function TypewriterText(props: TypewriterTextProps) {
  return (
    <Typography
      component="div"
      fontFamily={props.fontFamily}
      fontWeight={props.fontWeight}
      fontSize={props.fontSize}
      display="inline"
      sx={{
        color: props.fontColor,
      }}
    >
      {props.typewriterStrings && props.typewriterStrings.length !== 0 && (
        <Typewriter
          options={{
            strings: props.typewriterStrings.map((ts) => ts.textString),
            autoStart: true,
            loop: true,
            delay: 50,
            deleteSpeed: 50,
          }}
        />
      )}
      {!props.typewriterStrings ||
        (props.typewriterStrings.length === 0 &&
          "Add typewriter strings in Builder.io")}
    </Typography>
  );
}
