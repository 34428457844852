import useExperiment from "./useExperiment";
import { ReactNode } from "react";
import { useIsPreviewing } from "@builder.io/react";
import React from "react";

/** Props for `VariantBlock`. These should be registered as inputs using `Builder.registerComponent`. */
export interface VariantBlockProps {
  /** The unique name of the Amplitude experiment (called "Feature Flag" in Amplitude's UI) */
  experimentKey: string;

  /** If true, the block will be shown if the user’s variant cannot be determined.
   * (It will still be hidden if the variant can be determined but does not match.)
   * If false, the block will be hidden if the user’s variant does not exactly match the variantKey. */
  showByDefault: boolean;

  /** The variant identifier stored in Builder. When the user’s variant matches this key, the block will display. */
  variantKey: string;

  /** The child blocks being passed into VariantBlock by Builder users */
  children?: ReactNode;
}

/**
 * Displays children content based on the user's variant in an Amplitude experiment.
 * Intended for use with Builder.io.
 *
 * The component initially returns null until the experiment data has finished loading. Once it is loaded,
 * the user variant will be compared to the variantKey from Builder. If there's an exact match, the children
 * content will be displayed. If the experiment data has finished loading but the variant for this experiment
 * cannot be found, then the children content only will be displayed if showByDefault is true.
 *
 * Because this component renders client-side, there is a brief moment where the content
 * is blank before it pops in. In most cases, this should be fast, but it is worth keeping
 * in mind if the content is above the fold.
 *
 * NOTE: useAmplitudeStart must be called during page load for the Amplitude data to be available.
 *
 * @see {@link https://healthcareinc.atlassian.net/wiki/x/Y4CNug | RFC}
 */
export default function VariantBlock({
  experimentKey,
  showByDefault,
  variantKey,
  children,
}: VariantBlockProps) {
  const isPreviewing = useIsPreviewing();
  const variantState = useExperiment({ experimentKey });

  const userVariant = variantState?.variant;

  // User is in Builder Preview mode => render
  if (isPreviewing) return <div>{children}</div>;

  // No amplitude data yet => don't render
  if (!variantState.loaded) return null;

  // Can't determine user's variant AND default variant is turned off => don't render
  if (!variantState?.variant && !showByDefault) return null;

  // Can't determine user's variant AND default variant is turned on => render
  // OR
  // User's variant matches the variant key => render
  // Otherwise don't render
  const shouldRender =
    (!variantState?.variant && showByDefault) ||
    userVariant?.value == variantKey;
  if (!shouldRender) return null;

  return <div>{children}</div>;
}
