import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import type { HcState } from "./HcState";
import { update } from "./hcSlice";

/** Retrieve the `hc` object from `localStorage`. */
export function getHcStorage(): HcState | null {
  return JSON.parse(localStorage.getItem("hc") || "null");
}

/** Write the `hc` object to `localStorage`. */
export function setHcStorage(state: HcState): void {
  localStorage.setItem("hc", JSON.stringify(state));
}

/**
 * Set up the `hc` object in `localStorage`, needed for backward compatibility
 * with older pages.
 *
 * This hook creates a subscription to the store that runs on every dispatch.
 * It gets the current `HcState` from the store and then compares the `lastUpdated`
 * timestamp to the timestamp of the `hc` object in `localStorage`. If the Redux
 * store's `hc` was updated more recently than `localStorage`, then it writes the
 * updated `hc` object to `localStorage`.
 *
 * **WARNING**: It is not recommended to call this hook more than once per page.
 * Generally it should be called from `useHc`, which should be called near the root
 * of your page. If you need to access the `hc` object in the store, then call
 * `useAppSelector(state => state.hc)`. (Or better yet, select a specific field from `hc`,
 * like `useAppSelector(state => state.hc.location)`.)
 *
 * @param store - The Redux store for the app
 */
export default function useHcStorage(): HcState {
  const hc = useAppSelector((state) => state.hc);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const storedState = getHcStorage();
    // If we have a user ID in storage but not in Redux,
    // then this is probably the first time this hook has run
    // since the page loaded, so we should refresh the Redux data
    // with our previously computed state. (Unfortunately, you can't
    // really get this from the session API itself, so this is all
    // front end logic for now.)
    if (storedState?.healthcareUserId && !hc.healthcareUserId) {
      dispatch(update(storedState));
      return;
    }

    // If our Redux data has been updated more recently than our stored
    // data, then update localStorage.
    if (storedState && hc.lastUpdated > storedState.lastUpdated) {
      setHcStorage(hc);
    }
  }, [hc, dispatch]);

  return hc;
}
