import BuilderH1 from "./BuilderH1";
import useExperiment from "./useExperiment";
import type { ExperimentHeaderProps } from "./ExperimentComponents.types";
import getExperimentHeaderContent from "./getExperimentHeaderContent";
import React from "react";

/**
 * Displays different header content based on the user's variant in an Amplitude experiment.
 * Intended for use with Builder.io.
 *
 * The component initially renders with a header with an empty string until the experiment data has
 * finished loading. Once it is loaded, the content is chosen from the `variants` prop
 * using the `experimentKey`. If the experiment data has finished loading but the variant
 * for this experiment cannot be found, then the content for `defaultVariant` is rendered
 * instead.
 *
 * Because this component renders client-side, there is a brief moment where the content
 * is blank before it pops in. In most cases, this should be fast, but it is worth keeping
 * in mind if the content is above the fold.
 *
 * NOTE: useAmplitudeStart must be called during page load for the Amplitude data to be available.
 *
 * @see {@link https://healthcareinc.atlassian.net/wiki/x/Y4CNug | RFC}
 */
export default function ExperimentHeader1({
  experimentKey,
  defaultVariant,
  variants,
}: ExperimentHeaderProps) {
  const variantState = useExperiment({ experimentKey });
  const content = variantState.loaded
    ? getExperimentHeaderContent(
        variants,
        defaultVariant,
        variantState.variant?.value
      )
    : "";

  return <BuilderH1 text={content} />;
}
