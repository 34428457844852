import { Input, Component } from "@builder.io/sdk/dist/src/builder.class";
type defaultStyles = Component["defaultStyles"];

/**
 * Custom input options for registered Builder ExperimentHeader components in builderRegistry.
 */
export const builderExperimentHeaderInputs: Input[] = [
  {
    name: "experimentKey",
    type: "string",
    defaultValue: "",
    friendlyName: 'Experiment Name ("Feature Flag ")',
  },
  {
    name: "defaultVariant",
    type: "string",
    defaultValue: "control",
    friendlyName: "Default Variant",
  },
  {
    name: "variants",
    type: "list",
    defaultValue: [
      {
        variantKey: "control",
        variantContent: "This is the control",
      },
    ],
    subFields: [
      {
        name: "variantKey",
        type: "text",
      },
      {
        name: "variantContent",
        type: "string",
      },
    ],
  },
];

/**
 * Base default style applied to registered Builder components in builderRegistry file.
 * In builderRegistry, add a key/value for the 'fontSize' px value
 */
export const baseDefaultStyle: defaultStyles = {
  fontFamily: '"Poppins", sans-serif',
  fontWeight: "600",
  color: "rgba(48, 58, 82, 1)",
  zIndex: "1",
};
