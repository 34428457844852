import { Box, Button, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import React from "react";

/**
 * Props for the QuotesCarousel
 * @param quoteEntries - The content and byline of a quote
 * @param seePlansUrl - The URL to redirect to when the user clicks "See Plans and Pricing"
 */
export interface QuotesCarouselProps {
  quoteEntries: {
    content: string;
    byline: string;
  }[];
  seePlansUrl: string;
}

/**
 * Renders a carousel to display customer testimonials
 */
export default function QuotesCarousel(props: QuotesCarouselProps) {
  if (!props.quoteEntries || props.quoteEntries.length === 0)
    return <h1>Add quote entries in Builder.io</h1>;
  return (
    <Box maxWidth="952px">
      <Box>
        <Typography
          fontSize="200px"
          fontFamily="proxima-nova, sans-serif"
          fontWeight={600}
          textAlign="left"
          lineHeight={0.8}
          sx={{
            color: "#fed047",
            zIndex: 1,
          }}
          height="60px"
        >
          “
        </Typography>
        <Carousel animation="fade" navButtonsAlwaysInvisible>
          {props.quoteEntries.map((quote) => (
            <Box key={quote.content} textAlign="center">
              <Typography
                fontFamily="proxima-nova, sans-serif"
                fontWeight={600}
                sx={{
                  color: "#000",
                  minHeight: "100px",
                  zIndex: "2",
                  fontSize: {
                    xs: "20px",
                    sm: "20px",
                    md: "20px",
                    lg: "24px",
                    xl: "24px",
                  },
                }}
              >
                {quote.content}
              </Typography>
              <Typography
                fontFamily="proxima-nova, sans-serif"
                fontWeight={400}
                fontSize="18px"
                sx={{
                  color: "#000",
                }}
              >
                {quote.byline}
              </Typography>
            </Box>
          ))}
        </Carousel>
        <Typography
          fontSize="200px"
          fontFamily="proxima-nova, sans-serif"
          fontWeight={600}
          textAlign="right"
          height="60px"
          lineHeight={0.5}
          sx={{
            color: "#fed047",
            zIndex: 1,
          }}
        >
          ”
        </Typography>
      </Box>
      <Box textAlign="center">
        <Button
          onClick={() => {
            window.location.assign(props.seePlansUrl);
          }}
          sx={{
            color: "#f97d0e",
            backgroundColor: "#fff",
            minHeight: "5px",
            paddingLeft: "72px",
            paddingRight: "72px",
            fontWeight: "600",
            boxShadow: "0 2px 4px 0 rgba(0,0,0,.1)",
            "&:hover": {
              color: "#4a4a4a",
              backgroundColor: "#fff",
              boxShadow: "0 2px 4px 0 rgba(0,0,0,.1)",
              width: {
                xs: "290px",
                sm: "290px",
                md: "290px",
                lg: "340px",
                xl: "340px",
              },
            },
          }}
        >
          See Plans and Pricing
        </Button>
      </Box>
    </Box>
  );
}
