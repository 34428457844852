/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation CreateSession($payload: CreateSessionInput!) {\n    createSession(payload: $payload) {\n      sessionId\n      UserId\n    }\n  }\n": types.CreateSessionDocument,
    "\n  query GetInvocaCampaign($payload: CallCampaignPayload!) {\n    getCallCampaign(payload: $payload) {\n      data {\n        placement\n        logo\n        zip\n        impression_id\n        advertiser {\n          name\n          logo\n        }\n        phone {\n          promo_number_formatted\n          promo_number\n          tracking_url\n          link_invoca\n          tty_formatted\n        }\n        schedule {\n          sunday {\n            from\n            to\n          }\n          saturday {\n            from\n            to\n          }\n          tuesday {\n            from\n            to\n          }\n          Thursday {\n            from\n            to\n          }\n          wednesday {\n            from\n            to\n          }\n          friday {\n            from\n            to\n          }\n          monday {\n            from\n            to\n          }\n        }\n      }\n    }\n  }\n": types.GetInvocaCampaignDocument,
    "\n  query GetLocationFromIp($ip: String!) {\n    getLocationFromIp(ip: $ip) {\n      ip\n      country\n      countryName\n      stateId\n      stateName\n      stateAbbreviation\n      city\n      zip\n      latitude\n      longitude\n      countyId\n      countyName\n      fips\n      ssa\n      displayName\n    }\n  }\n": types.GetLocationFromIpDocument,
    "\n  query GetLocationFromUserIp {\n    getLocationFromUserIp {\n      ip\n      country\n      countryName\n      stateId\n      stateName\n      stateAbbreviation\n      city\n      zip\n      latitude\n      longitude\n      countyId\n      countyName\n      fips\n      ssa\n      displayName\n    }\n  }\n": types.GetLocationFromUserIpDocument,
    "\n  query GetLocationFromZip($zip: String!) {\n    getLocationFromZip(zip: $zip) {\n      ip\n      country\n      countryName\n      stateId\n      stateName\n      stateAbbreviation\n      city\n      zip\n      latitude\n      longitude\n      countyId\n      countyName\n      fips\n      ssa\n      displayName\n    }\n  }\n": types.GetLocationFromZipDocument,
    "\n  mutation LeadAudit($payload: LeadAuditPayload!) {\n    leadAudit(payload: $payload) {\n      data\n      error\n      status\n    }\n  }\n": types.LeadAuditDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateSession($payload: CreateSessionInput!) {\n    createSession(payload: $payload) {\n      sessionId\n      UserId\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSession($payload: CreateSessionInput!) {\n    createSession(payload: $payload) {\n      sessionId\n      UserId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetInvocaCampaign($payload: CallCampaignPayload!) {\n    getCallCampaign(payload: $payload) {\n      data {\n        placement\n        logo\n        zip\n        impression_id\n        advertiser {\n          name\n          logo\n        }\n        phone {\n          promo_number_formatted\n          promo_number\n          tracking_url\n          link_invoca\n          tty_formatted\n        }\n        schedule {\n          sunday {\n            from\n            to\n          }\n          saturday {\n            from\n            to\n          }\n          tuesday {\n            from\n            to\n          }\n          Thursday {\n            from\n            to\n          }\n          wednesday {\n            from\n            to\n          }\n          friday {\n            from\n            to\n          }\n          monday {\n            from\n            to\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetInvocaCampaign($payload: CallCampaignPayload!) {\n    getCallCampaign(payload: $payload) {\n      data {\n        placement\n        logo\n        zip\n        impression_id\n        advertiser {\n          name\n          logo\n        }\n        phone {\n          promo_number_formatted\n          promo_number\n          tracking_url\n          link_invoca\n          tty_formatted\n        }\n        schedule {\n          sunday {\n            from\n            to\n          }\n          saturday {\n            from\n            to\n          }\n          tuesday {\n            from\n            to\n          }\n          Thursday {\n            from\n            to\n          }\n          wednesday {\n            from\n            to\n          }\n          friday {\n            from\n            to\n          }\n          monday {\n            from\n            to\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetLocationFromIp($ip: String!) {\n    getLocationFromIp(ip: $ip) {\n      ip\n      country\n      countryName\n      stateId\n      stateName\n      stateAbbreviation\n      city\n      zip\n      latitude\n      longitude\n      countyId\n      countyName\n      fips\n      ssa\n      displayName\n    }\n  }\n"): (typeof documents)["\n  query GetLocationFromIp($ip: String!) {\n    getLocationFromIp(ip: $ip) {\n      ip\n      country\n      countryName\n      stateId\n      stateName\n      stateAbbreviation\n      city\n      zip\n      latitude\n      longitude\n      countyId\n      countyName\n      fips\n      ssa\n      displayName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetLocationFromUserIp {\n    getLocationFromUserIp {\n      ip\n      country\n      countryName\n      stateId\n      stateName\n      stateAbbreviation\n      city\n      zip\n      latitude\n      longitude\n      countyId\n      countyName\n      fips\n      ssa\n      displayName\n    }\n  }\n"): (typeof documents)["\n  query GetLocationFromUserIp {\n    getLocationFromUserIp {\n      ip\n      country\n      countryName\n      stateId\n      stateName\n      stateAbbreviation\n      city\n      zip\n      latitude\n      longitude\n      countyId\n      countyName\n      fips\n      ssa\n      displayName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetLocationFromZip($zip: String!) {\n    getLocationFromZip(zip: $zip) {\n      ip\n      country\n      countryName\n      stateId\n      stateName\n      stateAbbreviation\n      city\n      zip\n      latitude\n      longitude\n      countyId\n      countyName\n      fips\n      ssa\n      displayName\n    }\n  }\n"): (typeof documents)["\n  query GetLocationFromZip($zip: String!) {\n    getLocationFromZip(zip: $zip) {\n      ip\n      country\n      countryName\n      stateId\n      stateName\n      stateAbbreviation\n      city\n      zip\n      latitude\n      longitude\n      countyId\n      countyName\n      fips\n      ssa\n      displayName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation LeadAudit($payload: LeadAuditPayload!) {\n    leadAudit(payload: $payload) {\n      data\n      error\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation LeadAudit($payload: LeadAuditPayload!) {\n    leadAudit(payload: $payload) {\n      data\n      error\n      status\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;