import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import type { HcState, Location } from "./HcState";

export const hcSlice = createSlice({
  name: "hc",
  initialState: initialState,
  reducers: {
    update: (state: HcState, action: PayloadAction<HcState>) => ({
      ...state,
      ...action.payload,
      lastUpdated: Date.now(),
    }),
    updateLocation: (state: HcState, action: PayloadAction<Location>) => ({
      ...state,
      location: action.payload,
      lastUpdated: Date.now(),
    }),
  },
});

export const { update, updateLocation } = hcSlice.actions;

export default hcSlice.reducer;
